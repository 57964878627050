<table *ngIf="steps && steps.length">
  <thead>
    <tr class="border col">
      <!-- Expand -->
      <th>
        <div class="flex end">
          <lab-columns
            [game]="data.game"
            [columns]="columns"
            (setColumns)="setColumns.emit($event)"
          >
            <button title="Set columns visibility and precision">
              <i class="material-icons">settings</i>
            </button></lab-columns
          >
        </div>
      </th>
      <th *ngIf="columns[Column.Tree].show"><div class="header">Tree</div></th>
      <th class="num-icon">
        <div class="header">
          Items{{ rateLabel }}
          <button
            *ngIf="modifiedIgnore"
            title="Undo all ignored steps"
            (click)="resetIgnore.emit()"
          >
            <i class="material-icons">undo</i>
          </button>
        </div>
      </th>
      <th class="num-icon" *ngIf="columns[Column.Belts].show">
        <div class="header">
          Belts
          <button
            *ngIf="modifiedBelt"
            title="Undo all belt modifications"
            (click)="resetBelt.emit()"
          >
            <i class="material-icons">undo</i>
          </button>
        </div>
      </th>
      <th class="num-icon" *ngIf="columns[Column.Wagons].show">
        <div class="header">
          Wagons{{ rateLabel }}
          <button
            *ngIf="modifiedWagon"
            title="Undo all wagon modifications"
            (click)="resetWagon.emit()"
          >
            <i class="material-icons">undo</i>
          </button>
        </div>
      </th>
      <th colspan="3">
        <div class="header">
          Factories
          <button
            *ngIf="modifiedFactory"
            title="Undo all factory modifications"
            (click)="resetFactory.emit()"
          >
            <i class="material-icons">undo</i>
          </button>
        </div>
      </th>
      <th *ngIf="columns[Column.Overclock].show">
        <div class="header">
          Overclock
          <button
            *ngIf="modifiedOverclock"
            title="Undo all overclock modifications"
            (click)="resetOverclock.emit()"
          >
            <i class="material-icons">undo</i>
          </button>
        </div>
      </th>
      <th *ngIf="columns[Column.Beacons].show" colspan="3">
        <div class="header">
          <span *ngIf="settings.beaconReceivers" class="sub left">Each</span>
          <span>Beacons</span>
          <button
            *ngIf="modifiedBeacons"
            title="Undo all beacon modifications"
            (click)="resetBeacons.emit()"
          >
            <i class="material-icons">undo</i>
          </button>
          <span *ngIf="settings.beaconReceivers" class="sub right">Total</span>
        </div>
      </th>
      <th class="num-unit" *ngIf="columns[Column.Power].show">
        <div class="header">Power</div>
      </th>
      <th *ngIf="columns[Column.Pollution].show">
        <div class="header">Pollution{{ rateLabel }}</div>
      </th>
      <!-- Reset / Link -->
      <th *ngIf="columns[Column.Link].show && mode === ListMode.All">
        <button
          title="Export to CSV"
          data-test="lab-list-export"
          (click)="export()"
        >
          <i class="material-icons">file_download</i>
        </button>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let step of displayedSteps; trackBy: trackBy">
      <tr class="border row col">
        <td [id]="step.itemId">
          <div>
            <button
              *ngIf="details[step.id]?.length && !expanded[step.id]"
              title="Click to show details"
              (click)="expanded[step.id] = details[step.id][0]"
            >
              <i class="material-icons">expand_more</i>
            </button>
            <button
              *ngIf="mode === ListMode.All && expanded[step.id]"
              title="Click to hide details"
              (click)="expanded[step.id] = null"
            >
              <i class="material-icons">expand_less</i>
            </button>
          </div>
        </td>
        <td *ngIf="columns[Column.Tree].show" class="tree">
          <div class="flex links">
            <ng-container *ngIf="step.indent?.length">
              <div
                *ngFor="let i of step.indent; last as last"
                class="connect"
                [class.trail]="i"
                [class.last]="last"
              ></div>
              <div class="indent"></div>
            </ng-container>
            <lab-icon
              *ngIf="step.itemId; else recipeIcon"
              [data]="data"
              [iconId]="step.itemId"
              [tooltip]="data.itemEntities[step.itemId].name"
              [recipe]="data.recipeEntities[data.itemRecipeIds[step.itemId]]"
            >
            </lab-icon>
            <ng-template #recipeIcon>
              <lab-icon
                *ngIf="step.recipeId"
                [data]="data"
                [iconId]="step.recipeId"
                [tooltip]="data.recipeEntities[step.recipeId].name"
                [recipe]="data.recipeEntities[step.recipeId]"
              >
              </lab-icon>
            </ng-template>
          </div>
        </td>
        <td>
          <div class="flex" *ngIf="step.items">
            <span *ngIf="step.surplus?.nonzero()" class="monospace"
              >(+{{ rate(step.surplus, effPrecision[Column.Surplus]) }})</span
            >
            <span *ngIf="step.items" class="monospace">{{
              rate(
                step.items.sub(step.surplus || Rational.zero),
                effPrecision[Column.Items]
              )
            }}</span>
            <lab-icon
              class="button"
              [title]="
                itemSettings[step.itemId].ignore
                  ? 'Click to include'
                  : 'Click to ignore'
              "
              [data]="data"
              [iconId]="step.itemId"
              [class.ignored]="itemSettings[step.itemId].ignore"
              [tooltip]="data.itemEntities[step.itemId]?.name"
              [recipe]="data.recipeEntities[data.itemRecipeIds[step.itemId]]"
              [hoverIcon]="
                itemSettings[step.itemId].ignore
                  ? 'visibility'
                  : 'visibility_off'
              "
              (click)="ignoreItem.emit(step.itemId)"
            ></lab-icon>
          </div>
        </td>
        <td *ngIf="columns[Column.Belts].show">
          <ng-container *ngIf="itemSettings[step.itemId]?.belt as belt">
            <div *ngIf="step.belts" class="flex">
              <span class="monospace">{{
                rate(step.belts, effPrecision[Column.Belts])
              }}</span>
              <lab-select
                *ngIf="data.beltIds.indexOf(belt) !== -1; else pipePicker"
                header="Select a belt"
                [data]="data"
                [selected]="belt"
                [options]="data.beltIds"
                [displayRate]="displayRate"
                (selectId)="
                  setBelt.emit({
                    id: step.itemId,
                    value: $event,
                    def: settings.belt
                  })
                "
              >
                <lab-icon
                  class="button"
                  title="Click to change belt"
                  [data]="data"
                  [iconId]="belt"
                  [tooltip]="data.itemEntities[belt].name"
                  [item]="data.itemEntities[belt]"
                  [displayRate]="displayRate"
                ></lab-icon>
              </lab-select>
              <ng-template #pipePicker>
                <lab-select
                  *ngIf="data.pipeIds.indexOf(belt) !== -1; else pipeIcon"
                  header="Select a pipe"
                  [data]="data"
                  [selected]="belt"
                  [options]="data.pipeIds"
                  [displayRate]="displayRate"
                  (selectId)="
                    setBelt.emit({
                      id: step.itemId,
                      value: $event,
                      def: settings.pipe
                    })
                  "
                >
                  <lab-icon
                    class="button"
                    title="Click to change pipe"
                    [data]="data"
                    [iconId]="belt"
                    [tooltip]="data.itemEntities[belt].name"
                    [item]="data.itemEntities[belt]"
                    [displayRate]="displayRate"
                  ></lab-icon>
                </lab-select>
              </ng-template>
              <ng-template #pipeIcon>
                <lab-icon
                  [iconId]="belt"
                  [tooltip]="data.itemEntities[belt]?.name || PIPE"
                  [data]="data"
                ></lab-icon>
              </ng-template>
            </div>
          </ng-container>
        </td>
        <td *ngIf="columns[Column.Wagons].show">
          <ng-container *ngIf="itemSettings[step.itemId]?.wagon as wagon">
            <div *ngIf="step.wagons" class="flex">
              <span class="monospace">{{
                rate(step.wagons, effPrecision[Column.Wagons])
              }}</span>
              <ng-container
                *ngIf="
                  data.fluidWagonIds.indexOf(wagon) === -1;
                  else fluidWagon
                "
              >
                <ng-container
                  *ngIf="data.cargoWagonIds.length > 1; else wagonDefault"
                >
                  <lab-select
                    header="Select a cargo wagon"
                    [data]="data"
                    [selected]="wagon"
                    [options]="data.cargoWagonIds"
                    (selectId)="
                      setWagon.emit({
                        id: step.itemId,
                        value: $event,
                        def: settings.cargoWagon
                      })
                    "
                  >
                    <lab-icon
                      class="button"
                      title="Click to change cargo wagon"
                      [data]="data"
                      [iconId]="wagon"
                      [tooltip]="data.itemEntities[wagon].name"
                      [item]="data.itemEntities[wagon]"
                    ></lab-icon>
                  </lab-select>
                </ng-container>
              </ng-container>
              <ng-template #fluidWagon>
                <ng-container
                  *ngIf="data.fluidWagonIds.length > 1; else wagonDefault"
                >
                  <lab-select
                    header="Select a fluid wagon"
                    [data]="data"
                    [selected]="wagon"
                    [options]="data.fluidWagonIds"
                    (selectId)="
                      setWagon.emit({
                        id: step.itemId,
                        value: $event,
                        def: settings.fluidWagon
                      })
                    "
                  >
                    <lab-icon
                      class="button"
                      title="Click to change fluid wagon"
                      [data]="data"
                      [iconId]="wagon"
                      [tooltip]="data.itemEntities[wagon].name"
                      [item]="data.itemEntities[wagon]"
                    ></lab-icon>
                  </lab-select>
                </ng-container>
              </ng-template>
              <ng-template #wagonDefault>
                <lab-icon
                  [data]="data"
                  [iconId]="wagon"
                  [tooltip]="data.itemEntities[wagon].name"
                  [item]="data.itemEntities[wagon]"
                ></lab-icon>
              </ng-template>
            </div>
          </ng-container>
        </td>
        <ng-container *ngIf="recipeSettings[step.recipeId]?.factory as factory">
          <td class="num" [id]="step.recipeId">
            <div *ngIf="step.factories?.nonzero()" class="flex">
              <span class="monospace">{{
                factoryRate(
                  step.factories,
                  effPrecision[Column.Factories],
                  factory
                )
              }}</span>
            </div>
          </td>
          <td class="recipe-icon split-column">
            <div>
              <div class="flex">
                <lab-icon
                  [data]="data"
                  [iconId]="step.recipeId"
                  [tooltip]="data.recipeEntities[step.recipeId].name"
                  [recipe]="data.recipeEntities[step.recipeId]"
                ></lab-icon>
                <ng-container
                  *ngIf="data.recipeEntities[step.recipeId] as recipe"
                >
                  <ng-container
                    *ngIf="
                      data.game !== Game.DysonSphereProgram ||
                      factory !== ItemId.MiningDrill
                    "
                  >
                    <ng-container
                      *ngIf="
                        recipe.producers && recipe.producers.length > 1;
                        else factoryDefault
                      "
                    >
                      <lab-select
                        header="Select a factory"
                        [data]="data"
                        [selected]="factory"
                        [options]="recipe.producers"
                        (selectId)="
                          changeFactory(step.recipeId, $event, setFactory)
                        "
                      >
                        <lab-icon
                          class="button"
                          title="Click to change factory"
                          [data]="data"
                          [iconId]="factory"
                          [tooltip]="data.itemEntities[factory].name"
                          [item]="data.itemEntities[factory]"
                        ></lab-icon>
                      </lab-select>
                    </ng-container>
                    <ng-template #factoryDefault>
                      <lab-icon
                        [iconId]="factory"
                        [tooltip]="data.itemEntities[factory].name"
                        [item]="data.itemEntities[factory]"
                        [data]="data"
                      ></lab-icon>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </td>
          <td class="split-column">
            <div class="flex">
              <div
                *ngIf="recipeSettings[step.recipeId]?.factoryModules as modules"
                class="flex mod"
              >
                <lab-select
                  *ngFor="let mod of modules; index as i; first as first"
                  header="Select a module"
                  [data]="data"
                  [selected]="mod"
                  [options]="data.recipeModuleIds[step.recipeId]"
                  [includeEmptyModule]="data.game === Game.Factorio"
                  [displayRate]="displayRate"
                  (selectId)="
                    changeFactoryModules(
                      step.recipeId,
                      $event,
                      i,
                      recipeSettings[step.recipeId].factoryModules,
                      setFactoryModules
                    )
                  "
                >
                  <lab-icon
                    class="button module"
                    title="Click to change factory modules"
                    [data]="data"
                    [iconId]="mod"
                    [tooltip]="data.itemEntities[mod]?.name"
                    [item]="data.itemEntities[mod]"
                    [displayRate]="displayRate"
                  ></lab-icon>
                </lab-select>
              </div>
            </div>
          </td>
        </ng-container>
        <td colspan="3" *ngIf="!recipeSettings[step.recipeId]?.factory"></td>
        <td *ngIf="columns[Column.Overclock].show">
          <ng-container *ngIf="recipeSettings[step.recipeId]">
            <input
              type="number"
              title="Set overclock"
              placeholder="100"
              min="0"
              max="250"
              step="10"
              labValidateOverclock
              [ngModel]="recipeSettings[step.recipeId].overclock"
              (input)="changeOverclock(step.recipeId, $event, setOverclock)"
            />
            %
          </ng-container>
        </td>
        <ng-container *ngIf="columns[Column.Beacons].show">
          <ng-container *ngIf="recipeSettings[step.recipeId]?.beacon as beacon">
            <td>
              <div class="flex">
                <lab-input
                  title="Set number of beacons"
                  placeholder="# Beacons"
                  [digits]="2"
                  [value]="recipeSettings[step.recipeId].beaconCount"
                  (setValue)="
                    changeBeaconCount(step.recipeId, $event, setBeaconCount)
                  "
                >
                </lab-input>
              </div>
            </td>
            <td class="split-column">
              <div class="flex">
                <ng-container
                  *ngIf="gtZero(recipeSettings[step.recipeId].beaconCount)"
                >
                  <ng-container
                    *ngIf="data.beaconIds.length === 1; else beaconPicker"
                  >
                    <lab-icon
                      [iconId]="beacon"
                      [tooltip]="data.itemEntities[beacon].name"
                      [item]="data.itemEntities[beacon]"
                      [data]="data"
                    ></lab-icon
                  ></ng-container>
                  <ng-template #beaconPicker>
                    <lab-select
                      header="Select a beacon"
                      [data]="data"
                      [selected]="beacon"
                      [options]="data.beaconIds"
                      (selectId)="
                        changeBeacon(step.recipeId, $event, setBeacon)
                      "
                    >
                      <lab-icon
                        class="button"
                        title="Click to change beacon"
                        [data]="data"
                        [iconId]="beacon"
                        [tooltip]="data.itemEntities[beacon].name"
                        [item]="data.itemEntities[beacon]"
                      ></lab-icon>
                    </lab-select>
                  </ng-template>
                </ng-container>
              </div>
            </td>
            <td class="split-column">
              <ng-container
                *ngIf="gtZero(recipeSettings[step.recipeId].beaconCount)"
              >
                <div class="flex">
                  <div
                    *ngIf="
                      recipeSettings[step.recipeId]?.beaconModules as modules
                    "
                    class="flex mod"
                  >
                    <lab-select
                      *ngFor="let mod of modules; index as i; first as first"
                      header="Select a module"
                      [data]="data"
                      [selected]="mod"
                      [options]="data.beaconModuleIds"
                      [includeEmptyModule]="true"
                      (selectId)="
                        changeBeaconModules(
                          step.recipeId,
                          $event,
                          i,
                          modules,
                          setBeaconModules
                        )
                      "
                    >
                      <lab-icon
                        class="button module"
                        title="Click to change beacon modules"
                        [data]="data"
                        [iconId]="mod"
                        [tooltip]="data.itemEntities[mod]?.name"
                        [item]="data.itemEntities[mod]"
                      ></lab-icon>
                    </lab-select>
                  </div>
                  <div *ngIf="step.beacons">
                    <lab-input
                      title="Set total beacons"
                      placeholder="# Beacons"
                      [digits]="4"
                      [value]="step.beacons.toString()"
                      (setValue)="
                        setBeaconTotal.emit({
                          id: step.recipeId,
                          value: $event
                        })
                      "
                    >
                    </lab-input>
                  </div>
                </div>
              </ng-container>
            </td>
          </ng-container>
          <td colspan="3" *ngIf="!recipeSettings[step.recipeId]?.beacon"></td>
        </ng-container>
        <td *ngIf="columns[Column.Power].show">
          <div *ngIf="step.power?.nonzero()" class="flex">
            <span class="monospace">{{ power(step.power) }}</span>
          </div>
        </td>
        <td *ngIf="columns[Column.Pollution].show">
          <div *ngIf="step.pollution?.nonzero()" class="flex">
            <span class="monospace">{{
              rate(step.pollution, effPrecision[Column.Pollution])
            }}</span>
          </div>
        </td>
        <td class="nowrap" *ngIf="columns[Column.Link].show">
          <div class="flex links">
            <a
              *ngIf="step.items"
              class="button"
              title="Click to open this step in a new tab"
              [href]="step.href"
              target="_blank"
            >
              <i class="clickable material-icons">open_in_new</i>
            </a>
            <button
              *ngIf="recipeRaw[step.recipeId] || itemRaw[step.itemId]"
              title="Undo modifications to this step"
              data-test="lab-list-reset-step"
              (click)="resetStep(step)"
            >
              <i class="material-icons">undo</i>
            </button>
          </div>
        </td>
      </tr>
      <ng-container *ngIf="expanded[step.id]">
        <tr class="detail-header">
          <td [colSpan]="leftSpan">
            <div class="flex">
              <a class="button" [href]="link(step)">
                <i class="material-icons">link</i>
              </a>
            </div>
          </td>
          <td colspan="100" class="detail-tabs">
            <ul class="tabs">
              <li
                *ngIf="details[step.id].indexOf(StepDetailTab.Item) !== -1"
                [class.active]="expanded[step.id] === StepDetailTab.Item"
                (click)="expanded[step.id] = StepDetailTab.Item"
              >
                <span>Item</span>
              </li>
              <li
                *ngIf="details[step.id].indexOf(StepDetailTab.Recipe) !== -1"
                [class.active]="expanded[step.id] === StepDetailTab.Recipe"
                (click)="expanded[step.id] = StepDetailTab.Recipe"
              >
                <span>Recipe</span>
              </li>
              <li
                *ngIf="details[step.id].indexOf(StepDetailTab.Factory) !== -1"
                [class.active]="expanded[step.id] === StepDetailTab.Factory"
                (click)="expanded[step.id] = StepDetailTab.Factory"
              >
                <span>Factory</span>
              </li>
              <li
                *ngIf="details[step.id].indexOf(StepDetailTab.Recipes) !== -1"
                [class.active]="expanded[step.id] === StepDetailTab.Recipes"
                (click)="expanded[step.id] = StepDetailTab.Recipes"
              >
                <span>Recipes</span>
              </li>
            </ul>
          </td>
        </tr>
        <ng-container *ngIf="expanded[step.id] === StepDetailTab.Item">
          <tr class="details first" *ngIf="outputs[step.id].length">
            <td [colSpan]="leftSpan"></td>
            <td colspan="100"><span class="header">Sources</span></td>
          </tr>
          <tr
            *ngFor="let output of outputs[step.id]; last as isLast"
            class="details"
            [class.last]="isLast && !step.parents"
          >
            <ng-container *ngIf="output.outputs[step.itemId] as value">
              <td [colSpan]="leftSpan"></td>
              <td>
                <div class="flex">
                  <span *ngIf="step.items" class="monospace">{{
                    rate(step.items.mul(value), effPrecision[Column.Items])
                  }}</span>
                  <lab-icon
                    [data]="data"
                    [iconId]="step.itemId"
                    [tooltip]="data.itemEntities[step.itemId]?.name"
                    [recipe]="
                      data.recipeEntities[data.itemRecipeIds[step.itemId]]
                    "
                  >
                  </lab-icon>
                </div>
              </td>
              <td *ngIf="columns[Column.Belts].show">
                <ng-container *ngIf="itemSettings[step.itemId].belt as belt">
                  <div *ngIf="step.belts" class="flex">
                    <span class="monospace">{{
                      rate(step.belts.mul(value), effPrecision[Column.Belts])
                    }}</span>
                    <lab-icon
                      [data]="data"
                      [iconId]="belt"
                      [tooltip]="data.itemEntities[belt]?.name || PIPE"
                      [item]="data.itemEntities[belt]"
                      [displayRate]="displayRate"
                    ></lab-icon>
                  </div>
                </ng-container>
              </td>
              <td *ngIf="columns[Column.Wagons].show">
                <ng-container *ngIf="itemSettings[step.itemId]?.wagon as wagon">
                  <div *ngIf="step.wagons" class="flex">
                    <span class="monospace">{{
                      rate(step.wagons.mul(value), effPrecision[Column.Wagons])
                    }}</span>
                    <lab-icon
                      [data]="data"
                      [iconId]="wagon"
                      [tooltip]="data.itemEntities[wagon].name"
                      [item]="data.itemEntities[wagon]"
                    ></lab-icon>
                  </div>
                </ng-container>
              </td>
              <ng-container
                *ngIf="recipeSettings[output.recipeId]?.factory as factory"
              >
                <td>
                  <div class="flex" *ngIf="output.factories?.nonzero()">
                    <span class="monospace">{{
                      factoryRate(
                        output.factories,
                        effPrecision[Column.Factories],
                        factory
                      )
                    }}</span>
                  </div>
                </td>
                <td class="split-column">
                  <div>
                    <div class="flex">
                      <lab-icon
                        [data]="data"
                        [iconId]="output.recipeId"
                        [tooltip]="data.recipeEntities[output.recipeId].name"
                        [recipe]="data.recipeEntities[output.recipeId]"
                      ></lab-icon>
                      <lab-icon
                        *ngIf="
                          data.game !== Game.DysonSphereProgram ||
                          factory !== ItemId.MiningDrill
                        "
                        [data]="data"
                        [iconId]="factory"
                        [tooltip]="data.itemEntities[factory].name"
                        [item]="data.itemEntities[factory]"
                      ></lab-icon>
                    </div>
                  </div>
                </td>
              </ng-container>
              <td
                colspan="2"
                *ngIf="!recipeSettings[output.recipeId]?.factory"
              ></td>
              <td class="split-column" colspan="100">
                <div>
                  <div class="flex percent">
                    <i class="material-icons">arrow_forward</i>
                    <span class="monospace"
                      >{{ leftPad(rate(value, -2)) }}%</span
                    >
                    <lab-icon
                      [data]="data"
                      [iconId]="step.itemId"
                      [tooltip]="data.itemEntities[step.itemId]?.name"
                      [item]="data.itemEntities[step.itemId]"
                    ></lab-icon>
                  </div>
                </div>
              </td>
            </ng-container>
          </tr>
          <tr class="details" *ngIf="step.parents">
            <td [colSpan]="leftSpan"></td>
            <td colspan="100"><span class="header">Destinations</span></td>
          </tr>
          <tr
            *ngFor="
              let parent of step.parents | keyvalue: sortKeyValue;
              last as isLast
            "
            class="details"
            [class.last]="isLast"
          >
            <td [colSpan]="leftSpan"></td>
            <td>
              <div class="flex">
                <span *ngIf="step.items" class="monospace">{{
                  rate(step.items.mul(parent.value), effPrecision[Column.Items])
                }}</span>
                <lab-icon
                  [data]="data"
                  [iconId]="step.itemId"
                  [tooltip]="data.itemEntities[step.itemId]?.name"
                  [recipe]="
                    data.recipeEntities[data.itemRecipeIds[step.itemId]]
                  "
                >
                </lab-icon>
              </div>
            </td>
            <td *ngIf="columns[Column.Belts].show">
              <ng-container *ngIf="itemSettings[step.itemId].belt as belt">
                <div *ngIf="step.belts" class="flex">
                  <span class="monospace">{{
                    rate(
                      step.belts.mul(parent.value),
                      effPrecision[Column.Belts]
                    )
                  }}</span>
                  <lab-icon
                    [data]="data"
                    [iconId]="belt"
                    [tooltip]="data.itemEntities[belt]?.name || PIPE"
                    [item]="data.itemEntities[belt]"
                    [displayRate]="displayRate"
                  ></lab-icon>
                </div>
              </ng-container>
            </td>
            <td *ngIf="columns[Column.Wagons].show">
              <ng-container *ngIf="itemSettings[step.itemId]?.wagon as wagon">
                <div *ngIf="step.wagons" class="flex">
                  <span class="monospace">{{
                    rate(
                      step.wagons.mul(parent.value),
                      effPrecision[Column.Wagons]
                    )
                  }}</span>
                  <lab-icon
                    [data]="data"
                    [iconId]="wagon"
                    [tooltip]="data.itemEntities[wagon].name"
                    [item]="data.itemEntities[wagon]"
                  ></lab-icon>
                </div>
              </ng-container>
            </td>
            <ng-container
              *ngIf="recipeSettings[step.recipeId]?.factory as factory"
            >
              <td>
                <div class="flex" *ngIf="step.factories?.nonzero()">
                  <span class="monospace">{{
                    factoryRate(
                      step.factories.mul(parent.value),
                      effPrecision[Column.Factories],
                      factory
                    )
                  }}</span>
                </div>
              </td>
              <td class="split-column">
                <div>
                  <div class="flex">
                    <lab-icon
                      [data]="data"
                      [iconId]="step.recipeId"
                      [tooltip]="data.recipeEntities[step.recipeId].name"
                      [recipe]="data.recipeEntities[step.recipeId]"
                    ></lab-icon>
                    <lab-icon
                      *ngIf="
                        data.game !== Game.DysonSphereProgram ||
                        factory !== ItemId.MiningDrill
                      "
                      [data]="data"
                      [iconId]="factory"
                      [tooltip]="data.itemEntities[factory].name"
                      [item]="data.itemEntities[factory]"
                    ></lab-icon>
                  </div>
                </div>
              </td>
            </ng-container>
            <td
              colspan="2"
              *ngIf="!recipeSettings[step.recipeId]?.factory"
            ></td>
            <td class="split-column" colspan="100">
              <div>
                <div class="flex percent">
                  <span class="monospace"
                    >{{ leftPad(rate(parent.value, -2)) }}%</span
                  >
                  <i class="material-icons">arrow_forward</i>
                  <lab-icon
                    [data]="data"
                    [iconId]="parent.key"
                    [tooltip]="data.recipeEntities[parent.key].name"
                    [recipe]="data.recipeEntities[parent.key]"
                  ></lab-icon>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="expanded[step.id] === StepDetailTab.Recipe">
          <ng-container
            *ngIf="
              data.game === Game.Factorio &&
              data.itemEntities[recipeSettings[step.recipeId].factory].factory
                .mining
            "
          >
            <tr class="details first">
              <td [colSpan]="leftSpan"></td>
              <td colspan="100"><span class="header">Depletion</span></td>
            </tr>
            <ng-container
              *ngFor="let output of step.outputs | keyvalue: sortKeyValue"
            >
              <ng-container *ngIf="findStep(output.key) as outStep">
                <tr *ngIf="step.outputs[output.key] as percent" class="details">
                  <td [colSpan]="leftSpan"></td>
                  <td>
                    <div class="flex">
                      <span class="monospace">{{
                        rate(
                          outStep.items
                            .mul(percent)
                            .div(data.recipeR[step.recipeId].productivity),
                          effPrecision[Column.Items]
                        )
                      }}</span>
                      <lab-icon
                        [data]="data"
                        [iconId]="output.key"
                        [tooltip]="data.itemEntities[output.key]?.name"
                        [recipe]="
                          data.recipeEntities[data.itemRecipeIds[output.key]]
                        "
                      >
                      </lab-icon>
                    </div>
                  </td>
                  <td colspan="100"></td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
          <tr
            class="details"
            *ngIf="data.recipeR[step.recipeId].in"
            [class.first]="
              data.game !== Game.Factorio ||
              !data.itemEntities[recipeSettings[step.recipeId].factory].factory
                .mining
            "
          >
            <td [colSpan]="leftSpan"></td>
            <td colspan="100"><span class="header">Inputs</span></td>
          </tr>
          <ng-container
            *ngFor="
              let input of data.recipeR[step.recipeId].in
                | keyvalue: sortKeyValue
            "
          >
            <ng-container *ngIf="findStep(input.key) as inStep">
              <tr
                *ngIf="inStep.parents?.[step.recipeId] as percent"
                class="details"
              >
                <td [colSpan]="leftSpan"></td>
                <td>
                  <div class="flex">
                    <span *ngIf="inStep.items" class="monospace">{{
                      rate(
                        inStep.items.mul(percent),
                        effPrecision[Column.Items]
                      )
                    }}</span>
                    <lab-icon
                      [data]="data"
                      [iconId]="input.key"
                      [tooltip]="data.itemEntities[input.key]?.name"
                      [recipe]="
                        data.recipeEntities[data.itemRecipeIds[input.key]]
                      "
                    >
                    </lab-icon>
                  </div>
                </td>
                <td *ngIf="columns[Column.Belts].show">
                  <ng-container
                    *ngIf="itemSettings[inStep.itemId].belt as belt"
                  >
                    <div *ngIf="inStep.belts" class="flex">
                      <span class="monospace">{{
                        rate(
                          inStep.belts.mul(percent),
                          effPrecision[Column.Belts]
                        )
                      }}</span>
                      <lab-icon
                        [data]="data"
                        [iconId]="belt"
                        [tooltip]="data.itemEntities[belt]?.name || PIPE"
                        [item]="data.itemEntities[belt]"
                        [displayRate]="displayRate"
                      ></lab-icon>
                    </div>
                  </ng-container>
                </td>
                <td *ngIf="columns[Column.Wagons].show">
                  <ng-container
                    *ngIf="itemSettings[inStep.itemId]?.wagon as wagon"
                  >
                    <div *ngIf="inStep.wagons" class="flex">
                      <span class="monospace">{{
                        rate(
                          inStep.wagons.mul(percent),
                          effPrecision[Column.Wagons]
                        )
                      }}</span>
                      <lab-icon
                        [data]="data"
                        [iconId]="wagon"
                        [tooltip]="data.itemEntities[wagon].name"
                        [item]="data.itemEntities[wagon]"
                      ></lab-icon>
                    </div>
                  </ng-container>
                </td>
                <ng-container
                  *ngIf="recipeSettings[inStep.recipeId]?.factory as factory"
                >
                  <td>
                    <div class="flex" *ngIf="inStep.factories?.nonzero()">
                      <span class="monospace">{{
                        factoryRate(
                          inStep.factories.mul(percent),
                          effPrecision[Column.Factories],
                          factory
                        )
                      }}</span>
                    </div>
                  </td>
                  <td class="split-column">
                    <div>
                      <div class="flex">
                        <lab-icon
                          [data]="data"
                          [iconId]="inStep.recipeId"
                          [tooltip]="data.recipeEntities[inStep.recipeId].name"
                          [recipe]="data.recipeEntities[inStep.recipeId]"
                        >
                        </lab-icon>
                        <lab-icon
                          *ngIf="
                            data.game !== Game.DysonSphereProgram ||
                            factory !== ItemId.MiningDrill
                          "
                          [data]="data"
                          [iconId]="factory"
                          [tooltip]="data.itemEntities[factory].name"
                          [item]="data.itemEntities[factory]"
                        ></lab-icon>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <td
                  colspan="2"
                  *ngIf="!recipeSettings[inStep.recipeId]?.factory"
                ></td>
                <td class="split-column" colspan="100">
                  <div>
                    <div class="flex percent">
                      <span class="monospace"
                        >{{ leftPad(rate(percent, -2)) }}%</span
                      >
                      <i class="material-icons">arrow_forward</i>
                      <lab-icon
                        [data]="data"
                        [iconId]="step.recipeId"
                        [tooltip]="data.recipeEntities[step.recipeId]?.name"
                        [recipe]="data.recipeEntities[step.recipeId]"
                      ></lab-icon>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <tr class="details">
            <td [colSpan]="leftSpan"></td>
            <td colspan="100"><span class="header">Outputs</span></td>
          </tr>
          <ng-container
            *ngFor="
              let output of step.outputs | keyvalue: sortKeyValue;
              last as isLast
            "
          >
            <ng-container *ngIf="findStep(output.key) as outStep">
              <tr
                *ngIf="step.outputs[output.key] as percent"
                class="details"
                [class.last]="isLast"
              >
                <td [colSpan]="leftSpan"></td>
                <td>
                  <div class="flex">
                    <span class="monospace">{{
                      rate(
                        outStep.items.mul(percent),
                        effPrecision[Column.Items]
                      )
                    }}</span>
                    <lab-icon
                      [data]="data"
                      [iconId]="output.key"
                      [tooltip]="data.itemEntities[output.key]?.name"
                      [recipe]="
                        data.recipeEntities[data.itemRecipeIds[output.key]]
                      "
                    >
                    </lab-icon>
                  </div>
                </td>
                <td *ngIf="columns[Column.Belts].show">
                  <ng-container
                    *ngIf="itemSettings[outStep.itemId].belt as belt"
                  >
                    <div *ngIf="outStep.belts" class="flex">
                      <span class="monospace">{{
                        rate(
                          outStep.belts.mul(percent),
                          effPrecision[Column.Belts]
                        )
                      }}</span>
                      <lab-icon
                        [data]="data"
                        [iconId]="belt"
                        [tooltip]="data.itemEntities[belt]?.name || PIPE"
                        [item]="data.itemEntities[belt]"
                        [displayRate]="displayRate"
                      ></lab-icon>
                    </div>
                  </ng-container>
                </td>
                <td *ngIf="columns[Column.Wagons].show">
                  <ng-container
                    *ngIf="itemSettings[outStep.itemId]?.wagon as wagon"
                  >
                    <div *ngIf="outStep.wagons" class="flex">
                      <span class="monospace">{{
                        rate(
                          outStep.wagons.mul(percent),
                          effPrecision[Column.Wagons]
                        )
                      }}</span>
                      <lab-icon
                        [data]="data"
                        [iconId]="wagon"
                        [tooltip]="data.itemEntities[wagon].name"
                        [item]="data.itemEntities[wagon]"
                      ></lab-icon>
                    </div>
                  </ng-container>
                </td>
                <ng-container
                  *ngIf="recipeSettings[outStep.recipeId]?.factory as factory"
                >
                  <td>
                    <div class="flex" *ngIf="outStep.factories?.nonzero()">
                      <span class="monospace">{{
                        factoryRate(
                          outStep.factories,
                          effPrecision[Column.Factories],
                          factory
                        )
                      }}</span>
                    </div>
                  </td>
                  <td class="split-column">
                    <div>
                      <div class="flex">
                        <lab-icon
                          [data]="data"
                          [iconId]="outStep.recipeId"
                          [tooltip]="data.recipeEntities[outStep.recipeId].name"
                          [recipe]="data.recipeEntities[outStep.recipeId]"
                        ></lab-icon>
                        <lab-icon
                          *ngIf="
                            data.game !== Game.DysonSphereProgram ||
                            factory !== ItemId.MiningDrill
                          "
                          [data]="data"
                          [iconId]="factory"
                          [tooltip]="data.itemEntities[factory].name"
                          [item]="data.itemEntities[factory]"
                        ></lab-icon>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <td
                  colspan="2"
                  *ngIf="!recipeSettings[outStep.recipeId]?.factory"
                ></td>
                <td class="split-column" colspan="100">
                  <div>
                    <div class="flex percent">
                      <i class="material-icons">arrow_forward</i>
                      <span class="monospace"
                        >{{ leftPad(rate(percent, -2)) }}%</span
                      >
                      <lab-icon
                        [data]="data"
                        [iconId]="output.key"
                        [tooltip]="data.itemEntities[output.key]?.name"
                        [item]="data.itemEntities[output.key]"
                      ></lab-icon>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
        <tr
          *ngIf="expanded[step.id] === StepDetailTab.Factory"
          class="details first last"
        >
          <td [colSpan]="leftSpan"></td>
          <td colspan="100" class="factory">
            <div>
              <table>
                <ng-container
                  *ngFor="
                    let input of data.recipeR[step.recipeId].in | keyvalue
                  "
                >
                  <ng-container *ngIf="findStep(input.key) as inStep">
                    <tr *ngIf="inStep.parents[step.recipeId] as percent">
                      <td>
                        <div class="flex" *ngIf="step.items">
                          <span *ngIf="inStep.items" class="monospace">{{
                            rate(
                              inStep.items.mul(percent).div(step.factories),
                              effPrecision[Column.Items]
                            )
                          }}</span>
                          <lab-icon
                            [data]="data"
                            [iconId]="input.key"
                            [tooltip]="data.itemEntities[input.key]?.name"
                            [recipe]="
                              data.recipeEntities[data.itemRecipeIds[input.key]]
                            "
                          >
                          </lab-icon>
                        </div>
                      </td>
                      <td
                        [colSpan]="
                          itemSettings[inStep.itemId].belt === ItemId.Pipe
                            ? 2
                            : 0
                        "
                      >
                        <ng-container
                          *ngIf="itemSettings[inStep.itemId].belt as belt"
                        >
                          <div *ngIf="inStep.belts" class="flex">
                            <span class="monospace">{{
                              rate(
                                inStep.belts.mul(percent).div(step.factories),
                                effPrecision[Column.Belts]
                              )
                            }}</span>
                            <lab-icon
                              [data]="data"
                              [iconId]="belt"
                              [tooltip]="data.itemEntities[belt]?.name || PIPE"
                              [item]="data.itemEntities[belt]"
                              [displayRate]="displayRate"
                            ></lab-icon>
                          </div>
                        </ng-container>
                      </td>
                      <ng-container
                        *ngIf="itemSettings[inStep.itemId].belt !== ItemId.Pipe"
                      >
                        <td
                          *ngIf="
                            inserter(
                              inStep.items
                                .mul(percent)
                                .div(step.factories)
                                .div(DisplayRateVal[displayRate])
                            ) as ins
                          "
                        >
                          <div class="flex">
                            <span class="monospace">{{
                              rate(ins.value, 1)
                            }}</span>
                            <lab-icon
                              [data]="data"
                              [iconId]="ins.id"
                              [tooltip]="data.itemEntities[ins.id].name"
                              [item]="data.itemEntities[ins.id]"
                            ></lab-icon>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </ng-container>
                </ng-container>
              </table>
              <i class="material-icons">arrow_forward</i>
              <table>
                <tr>
                  <td>
                    <div class="flex"><span class="monospace">1</span></div>
                  </td>
                  <td class="split-column">
                    <div
                      *ngIf="recipeSettings[step.recipeId]?.factory as factory"
                    >
                      <div class="flex">
                        <lab-icon
                          [data]="data"
                          [iconId]="step.recipeId"
                          [tooltip]="data.recipeEntities[step.recipeId].name"
                          [recipe]="data.recipeEntities[step.recipeId]"
                        ></lab-icon>
                        <lab-icon
                          [data]="data"
                          [iconId]="factory"
                          [tooltip]="data.itemEntities[factory].name"
                          [item]="data.itemEntities[factory]"
                        ></lab-icon>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <i class="material-icons">arrow_forward</i>
              <table>
                <ng-container
                  *ngFor="
                    let output of data.recipeR[step.recipeId].out | keyvalue
                  "
                >
                  <tr
                    *ngIf="
                      output.value.div(
                        data.recipeR[step.recipeId].time
                      ) as items
                    "
                  >
                    <ng-container
                      *ngIf="itemSettings[output.key].belt !== ItemId.Pipe"
                    >
                      <td *ngIf="inserter(items) as ins">
                        <div class="flex">
                          <span class="monospace">{{
                            rate(ins.value, 1)
                          }}</span>
                          <lab-icon
                            [data]="data"
                            [iconId]="ins.id"
                            [tooltip]="data.itemEntities[ins.id].name"
                            [item]="data.itemEntities[ins.id]"
                          ></lab-icon>
                        </div>
                      </td>
                    </ng-container>
                    <td
                      [colSpan]="
                        itemSettings[output.key].belt === ItemId.Pipe ? 2 : 0
                      "
                    >
                      <div
                        *ngIf="itemSettings[output.key].belt as belt"
                        class="flex"
                      >
                        <span class="monospace">{{
                          rate(
                            items.div(beltSpeed[belt]),
                            effPrecision[Column.Belts]
                          )
                        }}</span>
                        <lab-icon
                          [data]="data"
                          [iconId]="belt"
                          [tooltip]="data.itemEntities[belt]?.name || PIPE"
                          [item]="data.itemEntities[belt]"
                          [displayRate]="displayRate"
                        >
                        </lab-icon>
                      </div>
                    </td>
                    <td>
                      <div class="flex">
                        <span class="monospace">{{
                          rate(
                            items.mul(DisplayRateVal[displayRate]),
                            effPrecision[Column.Items]
                          )
                        }}</span>
                        <lab-icon
                          [data]="data"
                          [iconId]="output.key"
                          [tooltip]="data.itemEntities[output.key]?.name"
                          [recipe]="
                            data.recipeEntities[data.itemRecipeIds[output.key]]
                          "
                        >
                        </lab-icon>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
            <div class="info" *ngIf="data.game === Game.Factorio">
              <span>
                * Inserters are calculated using target/capacity settings and
                0.18 experimental data from the
                <a
                  href="https://wiki.factorio.com/Inserters#Inserter_Throughput"
                  >wiki.</a
                >
              </span>
            </div>
          </td>
        </tr>
        <ng-container *ngIf="expanded[step.id] === StepDetailTab.Recipes">
          <tr class="details first">
            <td [colSpan]="leftSpan"></td>
            <td colspan="100">
              <div>
                <div class="header">Default Recipe</div>
                <div class="info">
                  Click to set a recipe as the default for this item. If set,
                  the default recipe is treated as the only way to produce this
                  item.
                </div>
                <div class="info">
                  The recipe must be enabled and must only produce this item.
                </div>
                <div
                  class="info warn"
                  *ngIf="data.itemRecipeIds[step.itemId] === null"
                >
                  Default was ignored due to a detected circular loop.
                </div>
              </div>
            </td>
          </tr>
          <tr class="details">
            <td [colSpan]="leftSpan"></td>
            <td colspan="100" class="recipes">
              <div class="list tile">
                <ng-container *ngFor="let recipeId of recipes[step.id]">
                  <ng-container *ngIf="data.recipeR[recipeId] as recipe">
                    <lab-icon
                      *ngIf="
                        disabledRecipes.indexOf(recipeId) === -1 &&
                        recipe.producesOnly(step.itemId)
                      "
                      class="button grid"
                      [class.selected]="
                        data.itemRecipeIds[step.itemId] === recipeId
                      "
                      [data]="data"
                      [iconId]="recipeId"
                      [tooltip]="recipe.name"
                      [recipe]="data.recipeEntities[recipeId]"
                      (click)="toggleDefaultRecipe(step.itemId, recipeId)"
                    >
                    </lab-icon>
                  </ng-container>
                </ng-container>
              </div>
            </td>
          </tr>
          <tr class="details">
            <td [colSpan]="leftSpan"></td>
            <td colspan="100">
              <div>
                <div class="header">Enabled Recipes</div>
                <div class="info">
                  Click recipes to enable or disable them. Disabled recipes will
                  not be used in the solution.
                </div>
              </div>
            </td>
          </tr>
          <tr class="details last">
            <td [colSpan]="leftSpan"></td>
            <td colspan="100" class="recipes">
              <div class="list tile">
                <lab-icon
                  *ngFor="let recipeId of recipes[step.id]"
                  class="button grid"
                  [class.ignored]="disabledRecipes.indexOf(recipeId) !== -1"
                  [data]="data"
                  [iconId]="recipeId"
                  [tooltip]="data.recipeEntities[recipeId].name"
                  [recipe]="data.recipeEntities[recipeId]"
                  (click)="toggleRecipe(recipeId)"
                ></lab-icon>
              </div>
            </td>
          </tr>
        </ng-container>
        <tr class="spacer">
          <td></td>
        </tr>
      </ng-container>
    </ng-container>
    <tr *ngIf="mode === ListMode.All" class="border row col">
      <td [colSpan]="leftSpan + 1">
        <div class="flex summary-label">
          <span class="monospace">Total:</span>
        </div>
      </td>
      <td *ngIf="columns[Column.Belts].show">
        <div
          class="flex"
          *ngFor="let tot of totalBelts | keyvalue: sortKeyValue"
        >
          <span class="monospace">{{ tot.value }}</span>
          <lab-icon
            *ngIf="data.itemEntities[tot.key] as item"
            [data]="data"
            [iconId]="tot.key"
            [tooltip]="item.name"
            [item]="item"
            [displayRate]="displayRate"
          >
          </lab-icon>
        </div>
      </td>
      <td *ngIf="columns[Column.Wagons].show">
        <div
          class="flex"
          *ngFor="let tot of totalWagons | keyvalue: sortKeyValue"
        >
          <span class="monospace">{{ tot.value }}</span>
          <lab-icon
            *ngIf="data.itemEntities[tot.key] as item"
            [data]="data"
            [iconId]="tot.key"
            [tooltip]="item.name"
            [item]="item"
          >
          </lab-icon>
        </div>
      </td>
      <td colspan="3">
        <div
          class="flex"
          *ngFor="let tot of totalFactories | keyvalue: sortKeyValue"
        >
          <span class="monospace">{{ tot.value }}</span>
          <lab-icon
            *ngIf="data.itemEntities[tot.key] as item; else totalRecipe"
            [data]="data"
            [iconId]="tot.key"
            [tooltip]="item.name"
            [item]="item"
          >
          </lab-icon>
          <ng-template #totalRecipe>
            <lab-icon
              *ngIf="data.recipeEntities[tot.key] as recipe"
              [data]="data"
              [iconId]="tot.key"
              [tooltip]="recipe.name"
              [recipe]="recipe"
            >
            </lab-icon>
          </ng-template>
        </div>
      </td>
      <td *ngIf="columns[Column.Overclock].show"></td>
      <td colspan="3" *ngIf="columns[Column.Beacons].show">
        <div
          class="flex"
          *ngFor="let tot of totalBeacons | keyvalue: sortKeyValue"
        >
          <span class="monospace">{{ tot.value }}</span>
          <lab-icon
            *ngIf="data.itemEntities[tot.key] as item"
            [data]="data"
            [iconId]="tot.key"
            [tooltip]="item.name"
            [item]="item"
          >
          </lab-icon>
        </div>
      </td>
      <td *ngIf="columns[Column.Power].show">
        <div class="flex">
          <span class="monospace">{{ totalPower }}</span>
        </div>
      </td>
      <td *ngIf="columns[Column.Pollution].show">
        <div class="flex">
          <span class="monospace">{{ totalPollution }}</span>
        </div>
      </td>
      <td *ngIf="columns[Column.Link].show"></td>
    </tr>
    <tr *ngIf="mode === ListMode.Focus && !selected">
      <td colspan="100" class="message">Select a node to see details</td>
    </tr>
  </tbody>
</table>
