<div data-test="lab-ranker-open" (click)="clickOpen()">
  <ng-content></ng-content>
</div>
<lab-dialog *ngIf="open" (closeDialog)="close()">
  <div class="dialog-title">
    <div class="header">Select modules</div>
    <button (click)="cancel()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div #scroll class="panel scroll" [style.width.rem]="width">
    <div class="tile">
      <lab-icon
        tooltip="No Module"
        class="grid"
        data-test="lab-ranker-none"
        [class.disabled]="!canAdd(ItemId.Module)"
        [class.selected]="!editValue.length"
        [data]="data"
        [iconId]="ItemId.Module"
        [text]="text(ItemId.Module)"
        [scrollTop]="scroll.scrollTop"
        (click)="clickId(ItemId.Module)"
      >
      </lab-icon>
    </div>
    <div class="tile" *ngFor="let row of rows">
      <lab-icon
        *ngFor="let id of row"
        class="grid"
        data-test="lab-ranker-option"
        [class.disabled]="!canAdd(id)"
        [class.selected]="editValue.indexOf(id) !== -1"
        [data]="data"
        [iconId]="id"
        [text]="text(id)"
        [tooltip]="data.itemEntities[id] ? data.itemEntities[id].name : null"
        [item]="data.itemEntities[id]"
        [scrollTop]="scroll.scrollTop"
        [displayRate]="displayRate"
        (click)="clickId(id)"
      ></lab-icon>
    </div>
  </div>
  <ng-container *ngIf="edited">
    <div class="info">
      (Optional) Select another module to use as a fallback
    </div>
    <div class="dialog-buttons">
      <button (click)="cancel()"><span class="text">Cancel</span></button>
      <button class="green" data-test="lab-toggle-close" (click)="close()">
        Confirm
      </button>
    </div>
  </ng-container>
</lab-dialog>
