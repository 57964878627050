<div data-test="lab-select-open" (click)="open = true">
  <ng-content></ng-content>
</div>
<lab-dialog *ngIf="open" (closeDialog)="cancel()">
  <div class="dialog-title">
    <div class="header">{{ header }}</div>
    <button (click)="cancel()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div #scroll class="panel scroll" [style.width.rem]="width">
    <div class="tile" *ngIf="includeEmptyModule">
      <lab-icon
        tooltip="No Module"
        class="grid"
        [class.selected]="selected === ItemId.Module"
        data-test="lab-select-none"
        [data]="data"
        [iconId]="ItemId.Module"
        [scrollTop]="scroll.scrollTop"
        (click)="clickId(ItemId.Module)"
      >
      </lab-icon>
    </div>
    <div class="tile" *ngFor="let row of rows">
      <ng-container *ngFor="let id of row">
        <lab-icon
          class="grid"
          [class.selected]="id === selected"
          data-test="lab-select-option"
          [data]="data"
          [iconId]="id"
          [tooltip]="
            data.itemEntities[id]?.name || data.recipeEntities[id]?.name || null
          "
          [item]="selectType === IdType.Item ? data.itemEntities[id] : null"
          [recipe]="
            selectType === IdType.Recipe ? data.recipeEntities[id] : null
          "
          [displayRate]="displayRate"
          [scrollTop]="scroll.scrollTop"
          (click)="clickId(id)"
        ></lab-icon>
      </ng-container>
    </div>
  </div>
</lab-dialog>
