<button
  title="Select disabled recipes"
  data-test="lab-toggle-open"
  (click)="clickOpen()"
>
  <span class="text"
    >{{ selected.length }} Recipe{{ selected.length === 1 ? '' : 's' }}</span
  >
</button>
<lab-dialog *ngIf="open" [style.width.rem]="width" (closeDialog)="close()">
  <div class="dialog-title">
    <div class="header">Select recipes</div>
    <div class="dialog-menu">
      <input
        class="search"
        *ngIf="search"
        data-test="lab-toggle-search-value"
        labFocus
        [(ngModel)]="searchValue"
        (input)="inputSearch()"
      />
      <button
        *ngIf="!search"
        data-test="lab-toggle-search"
        (click)="search = true"
      >
        <i class="material-icons">search</i>
      </button>
      <button (click)="cancel()">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <div #scrollContainer class="panel scroll" (scroll)="scrollPanel()">
    <div class="flex buttons">
      <button
        [disabled]="!editValue.length"
        (click)="edited = true; editValue = []"
      >
        <span class="text">Enable all</span>
      </button>
      <button
        [disabled]="editValue.length === data.complexRecipeIds.length"
        (click)="edited = true; editValue = data.complexRecipeIds"
      >
        <span class="text">Disable all</span>
      </button>
    </div>
    <div class="flex panel dark tile">
      <lab-icon
        *ngFor="let id of complexRecipeIds"
        class="button grid"
        data-test="lab-toggle-recipe"
        [class.ignored]="editValue.indexOf(id) !== -1"
        [data]="data"
        [iconId]="id"
        [scrollTop]="scrollTop"
        [tooltip]="data.recipeEntities[id].name"
        [recipe]="data.recipeEntities[id]"
        (click)="clickId(id)"
      ></lab-icon>
    </div>
  </div>
  <div class="dialog-buttons">
    <button (click)="cancel()"><span class="text">Cancel</span></button>
    <button class="green" data-test="lab-toggle-close" (click)="close()">
      Confirm
    </button>
  </div>
</lab-dialog>
