<div data-test="lab-picker-open" (click)="clickOpen()">
  <ng-content></ng-content>
</div>
<lab-dialog *ngIf="open" (closeDialog)="cancel()">
  <div class="dialog-title">
    <div class="header">Select an item to produce</div>
    <div class="dialog-menu">
      <input
        class="search"
        *ngIf="search"
        data-test="lab-picker-search-value"
        labFocus
        [(ngModel)]="searchValue"
        (input)="inputSearch()"
      />
      <button
        *ngIf="!search"
        data-test="lab-picker-search"
        (click)="search = true"
      >
        <i class="material-icons">search</i>
      </button>
      <button (click)="cancel()">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <div class="panel">
    <div #tabsScroll class="tabs">
      <ng-container *ngFor="let id of categoryIds">
        <lab-icon
          *ngIf="data.categoryEntities[id] as category"
          [class.selected]="id === tab"
          [data]="data"
          [iconId]="id"
          [scale]="false"
          [scrollLeft]="tabsScroll.scrollLeft"
          [tooltip]="category.name"
          (click)="tab = id"
        ></lab-icon>
      </ng-container>
    </div>
    <div #tabScroll class="tab panel">
      <div class="tile">
        <div class="row" *ngFor="let row of categoryItemRows[tab]">
          <lab-icon
            *ngFor="let id of row"
            class="grid"
            [data]="data"
            [iconId]="id"
            [scrollTop]="tabScroll.scrollTop"
            [tooltip]="data.itemEntities[id].name"
            [recipe]="data.recipeEntities[data.itemRecipeIds[id]]"
            (click)="selectId.emit(id); open = false"
          ></lab-icon>
        </div>
      </div>
    </div>
  </div>
</lab-dialog>
