<h2>Products</h2>
<div class="panel">
  <div class="product-row" *ngFor="let product of products; trackBy: trackBy">
    <ng-container *ngIf="product && data.itemEntities[product.itemId]">
      <button title="Remove product" (click)="removeProduct.emit(product.id)">
        <i class="material-icons">remove</i>
      </button>
      <span>Produce</span>
      <lab-picker
        [data]="data"
        [selected]="product.itemId"
        (selectId)="changeItem(product, $event)"
      >
        <lab-icon
          class="button"
          title="Change item"
          hoverIcon="settings"
          [data]="data"
          [iconId]="product.itemId"
          [tooltip]="data.itemEntities[product.itemId].name"
          [item]="data.itemEntities[product.itemId]"
          [displayRate]="displayRate"
        ></lab-icon
      ></lab-picker>
      <span>from</span>
      <lab-input
        title="Set output rate"
        placeholder="Rate"
        [value]="product.rate"
        (setValue)="setRate.emit({ id: product.id, value: $event })"
      ></lab-input>
      <lab-options
        class="ratetype"
        title="Change rate type"
        [selected]="product.rateType"
        [options]="rateTypeOptions"
        (selectId)="setRateType.emit({ id: product.id, value: $event })"
      ></lab-options>
      <ng-container
        *ngIf="productSteps[product.itemId].length > 0; else recipeNone"
      >
        <ng-container *ngIf="product.viaId as id">
          <span>of</span>
          <ng-container
            *ngIf="productSteps[product.itemId].length === 1; else viaPicker"
          >
            <lab-icon
              *ngIf="product.rateType === RateType.Factories; else singleItem"
              class="pad"
              [iconId]="id"
              [tooltip]="data.recipeEntities[id].name"
              [recipe]="data.recipeEntities[id]"
              [data]="data"
            >
            </lab-icon>
            <ng-template #singleItem>
              <lab-icon
                class="pad"
                [iconId]="id"
                [tooltip]="data.itemEntities[id].name"
                [item]="data.itemEntities[id]"
                [data]="data"
              >
              </lab-icon>
            </ng-template>
          </ng-container>
          <ng-template #viaPicker>
            <lab-select
              *ngIf="product.rateType === RateType.Factories; else itemPicker"
              header="Select a step to calculate by"
              [data]="data"
              [selected]="id"
              [options]="productOptions[product.id]"
              [selectType]="IdType.Recipe"
              (selectId)="setVia.emit({ id: product.id, value: $event })"
            >
              <lab-icon
                class="button"
                title="Change rate step"
                hoverIcon="settings"
                [data]="data"
                [iconId]="id"
                [tooltip]="data.recipeEntities[id].name"
                [recipe]="data.recipeEntities[id]"
              >
              </lab-icon>
            </lab-select>
            <ng-template #itemPicker>
              <lab-select
                header="Select a step to calculate by"
                [data]="data"
                [selected]="id"
                [options]="productOptions[product.id]"
                (selectId)="setVia.emit({ id: product.id, value: $event })"
              >
                <lab-icon
                  class="button"
                  title="Change rate step"
                  hoverIcon="settings"
                  [data]="data"
                  [iconId]="id"
                  [tooltip]="data.itemEntities[id].name"
                  [item]="data.itemEntities[id]"
                >
                </lab-icon>
              </lab-select>
            </ng-template>
          </ng-template>
          <ng-container *ngIf="product.rateType === RateType.Belts">
            <span>using</span>
            <ng-container
              *ngIf="product.viaSetting || itemSettings[id].belt as belt"
            >
              <lab-select
                *ngIf="data.beltIds.indexOf(belt) !== -1; else pipePicker"
                header="Select a belt"
                [data]="data"
                [selected]="belt"
                [options]="data.beltIds"
                [displayRate]="displayRate"
                (selectId)="
                  setViaSetting.emit({
                    id: product.id,
                    value: $event,
                    def: itemSettings[id].belt
                  })
                "
              >
                <lab-icon
                  class="button"
                  tooltip="Change rate step belt"
                  [data]="data"
                  [iconId]="belt"
                ></lab-icon>
              </lab-select>
              <ng-template #pipePicker>
                <lab-select
                  *ngIf="data.pipeIds.indexOf(belt) !== -1; else pipeIcon"
                  header="Select a pipe"
                  [data]="data"
                  [selected]="belt"
                  [options]="data.pipeIds"
                  [displayRate]="displayRate"
                  (selectId)="
                    setViaSetting.emit({
                      id: product.id,
                      value: $event,
                      def: itemSettings[id].belt
                    })
                  "
                >
                  <lab-icon
                    class="button"
                    tooltip="Change rate step pipe"
                    [data]="data"
                    [iconId]="belt"
                  ></lab-icon>
                </lab-select>
              </ng-template>
              <ng-template #pipeIcon>
                <lab-icon
                  [iconId]="belt"
                  [tooltip]="data.itemEntities[belt]?.name || PIPE"
                  [data]="data"
                ></lab-icon>
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="product.rateType === RateType.Wagons">
            <ng-container
              *ngIf="product.viaSetting || itemSettings[id].wagon as wagon"
            >
              <ng-container
                *ngIf="
                  data.fluidWagonIds.indexOf(wagon) === -1;
                  else fluidWagon
                "
              >
                <ng-container *ngIf="data.cargoWagonIds.length > 1">
                  <span>using</span>
                  <lab-select
                    header="Select a cargo wagon"
                    [data]="data"
                    [selected]="wagon"
                    [options]="data.cargoWagonIds"
                    (selectId)="
                      setViaSetting.emit({
                        id: product.id,
                        value: $event,
                        def: itemSettings[id].wagon
                      })
                    "
                  >
                    <lab-icon
                      class="button"
                      title="Change rate step wagon"
                      [data]="data"
                      [iconId]="wagon"
                      [tooltip]="data.itemEntities[wagon].name"
                      [item]="data.itemEntities[wagon]"
                    ></lab-icon>
                  </lab-select>
                </ng-container>
              </ng-container>
              <ng-template #fluidWagon>
                <ng-container *ngIf="data.fluidWagonIds.length > 1">
                  <span>using</span>
                  <lab-select
                    header="Select a fluid wagon"
                    [data]="data"
                    [selected]="wagon"
                    [options]="data.fluidWagonIds"
                    (selectId)="
                      setViaSetting.emit({
                        id: product.id,
                        value: $event,
                        def: itemSettings[id].wagon
                      })
                    "
                  >
                    <lab-icon
                      class="button"
                      title="Change rate step wagon"
                      [data]="data"
                      [iconId]="wagon"
                      [tooltip]="data.itemEntities[wagon].name"
                      [item]="data.itemEntities[wagon]"
                    ></lab-icon>
                  </lab-select>
                </ng-container>
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="product.rateType === RateType.Factories">
            <ng-container *ngIf="product.viaSetting as factory">
              <ng-container *ngIf="data.recipeEntities[id] as recipe">
                <ng-container
                  *ngIf="
                    data.game !== Game.DysonSphereProgram ||
                    factory !== ItemId.MiningDrill
                  "
                >
                  <span>using</span>
                  <ng-container
                    *ngIf="
                      recipe.producers && recipe.producers.length > 1;
                      else factoryDefault
                    "
                  >
                    <lab-select
                      header="Select a factory"
                      [data]="data"
                      [selected]="factory"
                      [options]="recipe.producers"
                      (selectId)="
                        changeFactory(id, $event, setViaSetting, product.id)
                      "
                    >
                      <lab-icon
                        class="button"
                        title="Change rate step factory"
                        [data]="data"
                        [iconId]="factory"
                        [tooltip]="data.itemEntities[factory].name"
                        [item]="data.itemEntities[factory]"
                      ></lab-icon>
                    </lab-select>
                  </ng-container>
                  <ng-template #factoryDefault>
                    <lab-icon
                      [iconId]="factory"
                      [tooltip]="data.itemEntities[factory].name"
                      [item]="data.itemEntities[factory]"
                      [data]="data"
                    ></lab-icon>
                  </ng-template>
                </ng-container>
                <div *ngIf="product.viaFactoryModules as modules" class="flex">
                  <lab-select
                    *ngFor="let mod of modules; index as i; first as first"
                    header="Select a module"
                    [data]="data"
                    [selected]="mod"
                    [options]="data.recipeModuleIds[id]"
                    [includeEmptyModule]="data.game === Game.Factorio"
                    [displayRate]="displayRate"
                    (selectId)="
                      changeFactoryModules(
                        id,
                        $event,
                        i,
                        product.viaFactoryModules,
                        setViaFactoryModules,
                        product.id,
                        product.viaSetting
                      )
                    "
                  >
                    <lab-icon
                      class="button"
                      title="Change rate step factory module"
                      [class.module]="!first"
                      [data]="data"
                      [iconId]="mod"
                      [tooltip]="data.itemEntities[mod]?.name"
                      [item]="data.itemEntities[mod]"
                      [displayRate]="displayRate"
                    ></lab-icon>
                  </lab-select>
                </div>
                <div *ngIf="product.viaOverclock as overclock" class="flex">
                  <input
                    type="number"
                    title="Set rate step overclock"
                    placeholder="100"
                    min="0"
                    max="250"
                    step="10"
                    labValidateOverclock
                    [ngModel]="product.viaOverclock"
                    (input)="
                      changeOverclock(
                        id,
                        $event,
                        setViaOverclock,
                        product.id,
                        product.viaSetting
                      )
                    "
                  />
                  <span style="margin-left: 0.25rem">%</span>
                </div>
                <ng-container *ngIf="product.viaBeacon as beacon">
                  <lab-input
                    title="Set rate step number of beacons"
                    placeholder="# Beacons"
                    [digits]="2"
                    [value]="product.viaBeaconCount"
                    (setValue)="
                      changeBeaconCount(
                        id,
                        $event,
                        setViaBeaconCount,
                        product.id,
                        product.viaSetting
                      )
                    "
                  >
                  </lab-input>
                  <ng-container *ngIf="gtZero(product.viaBeaconCount)">
                    <ng-container
                      *ngIf="data.beaconIds.length === 1; else beaconPicker"
                    >
                      <lab-icon
                        [iconId]="beacon"
                        [tooltip]="data.itemEntities[beacon].name"
                        [item]="data.itemEntities[beacon]"
                        [data]="data"
                      ></lab-icon
                    ></ng-container>
                    <ng-template #beaconPicker>
                      <lab-select
                        header="Select a beacon"
                        [data]="data"
                        [selected]="beacon"
                        [options]="data.beaconIds"
                        (selectId)="
                          changeBeacon(
                            id,
                            $event,
                            setViaBeacon,
                            product.id,
                            product.viaSetting
                          )
                        "
                      >
                        <lab-icon
                          class="button"
                          title="Set rate step beacon"
                          [data]="data"
                          [iconId]="beacon"
                          [tooltip]="data.itemEntities[beacon].name"
                          [item]="data.itemEntities[beacon]"
                        ></lab-icon>
                      </lab-select>
                    </ng-template>
                    <div
                      *ngIf="product.viaBeaconModules as modules"
                      class="flex"
                    >
                      <lab-select
                        *ngFor="let mod of modules; index as i; first as first"
                        header="Select a module"
                        [data]="data"
                        [selected]="mod"
                        [options]="data.beaconModuleIds"
                        [includeEmptyModule]="true"
                        (selectId)="
                          changeBeaconModules(
                            id,
                            $event,
                            i,
                            modules,
                            setViaBeaconModules,
                            product.id,
                            product.viaSetting,
                            product.viaBeacon
                          )
                        "
                      >
                        <lab-icon
                          class="button"
                          title="Change rate step beacon module"
                          [class.module]="!first"
                          [data]="data"
                          [iconId]="mod"
                          [tooltip]="data.itemEntities[mod]?.name"
                          [item]="data.itemEntities[mod]"
                        ></lab-icon>
                      </lab-select>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #recipeNone>
        <div class="invalid">No matching enabled recipes</div>
      </ng-template>
    </ng-container>
  </div>
  <div>
    <lab-picker
      [data]="data"
      [selected]="data.itemIds[0]"
      (selectId)="addProduct.emit($event)"
    >
      <button title="Add a product">
        <i class="material-icons">add</i>
        <div class="text">Add a product</div>
      </button></lab-picker
    >
    <lab-options
      title="Select display rate"
      [selected]="displayRate"
      [options]="DisplayRateOptions"
      (selectId)="setDisplayRate.emit({ value: $event, prev: displayRate })"
    ></lab-options>
  </div>
</div>
