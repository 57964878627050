<div class="flex">
  <div>Link text</div>
  <lab-options
    title="Select flow link text"
    [selected]="linkText$ | async"
    [options]="options"
    (selectId)="setLinkText($event)"
  ></lab-options>
  <div>Link size</div>
  <lab-options
    title="Select flow link size"
    [selected]="linkSize$ | async"
    [options]="options"
    (selectId)="setLinkSize($event)"
  ></lab-options>
  <div class="flex">
    <span>Sankey align</span>
    <a href="https://github.com/d3/d3-sankey#alignments" target="_blank"
      ><i class="material-icons small">help</i></a
    >
  </div>
  <lab-options
    title="Select sankey alignment"
    [selected]="sankeyAlign$ | async"
    [options]="sankeyAlignOptions"
    (selectId)="setSankeyAlign($event)"
  ></lab-options>
  <button
    *ngIf="sankeyData$ | async as data"
    title="Export to JSON"
    data-test="lab-flow-container-export"
    (click)="export(data)"
  >
    <i class="material-icons">file_download</i>
  </button>
</div>
<lab-sankey
  [sankeyData]="sankeyData$ | async"
  [sankeyAlign]="sankeyAlign$ | async"
  (selectNode)="setSelected($event)"
></lab-sankey>
<lab-list-container
  [mode]="ListMode.Focus"
  [steps]="steps$ | async"
  [selected]="selected"
></lab-list-container>
