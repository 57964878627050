<input
  type="text"
  [style.width.rem]="width"
  [title]="title"
  [placeholder]="placeholder"
  [ngModel]="value"
  labValidateNumber
  [labValidateMinimum]="minimum"
  data-test="lab-input-input"
  (input)="changeValue($event)"
/>
<div>
  <button
    title="Increase 1"
    data-test="lab-input-increase"
    (click)="increase()"
  >
    <i class="material-icons">arrow_drop_up</i>
  </button>
  <button
    title="Decrease 1"
    data-test="lab-input-decrease"
    [disabled]="isMinimum"
    (click)="decrease()"
  >
    <i class="material-icons">arrow_drop_down</i>
  </button>
</div>
