<lab-matrix
  [data]="data$ | async"
  [result]="result$ | async"
  [costFactor]="costFactor$ | async"
  [costFactory]="costFactory$ | async"
  [costInput]="costInput$ | async"
  [costIgnored]="costIgnored$ | async"
  [recipeRaw]="recipeRaw$ | async"
  [modifiedCost]="modifiedCost$ | async"
  [modifiedRecipeCost]="modifiedRecipeCost$ | async"
  (setCostFactor)="setCostFactor($event)"
  (setCostFactory)="setCostFactory($event)"
  (setCostInput)="setCostInput($event)"
  (setCostIgnored)="setCostIgnored($event)"
  (setRecipeCost)="setRecipeCost($event)"
  (resetCost)="resetCost()"
  (resetRecipeCost)="resetRecipeCost()"
></lab-matrix>
