<div class="image">
  <img
    *ngIf="icon"
    src="assets/transparent.gif"
    class="icon"
    [class.shadow]="hover && hoverIcon"
    [style.background-position]="icon.position"
    [style.background-image]="icon.file ? 'url(' + icon.file + ')' : ''"
    [ngClass]="iconId"
    [class.scale]="scale"
  />
  <div *ngIf="text" class="icon-text">{{ text }}</div>
</div>
<div class="overlay" *ngIf="hover && hoverIcon">
  <i class="material-icons">{{ hoverIcon }}</i>
</div>
<div
  *ngIf="hover && (recipe || item || tooltip)"
  [style.marginTop.px]="tooltipMarginTop"
  [style.marginLeft.px]="-scrollLeft"
  class="tooltip panel dark"
  (mouseenter)="hover = false"
>
  <div *ngIf="tooltip" class="header nowrap">
    {{ tooltip }}
  </div>
  <div *ngIf="recipe" class="flex">
    <lab-icon
      iconId="time"
      [text]="recipe.time.toString()"
      [data]="data"
    ></lab-icon>
    <ng-container *ngFor="let ingredient of recipe.in | keyvalue">
      <lab-icon
        [iconId]="ingredient.key"
        [text]="ingredient.value.toString()"
        [data]="data"
      ></lab-icon>
    </ng-container>
    <i class="material-icons">arrow_forward</i>
    <ng-container *ngIf="recipe.out">
      <ng-container *ngFor="let output of recipe.out | keyvalue" class="flex">
        <lab-icon
          [iconId]="output.key"
          [text]="output.value.toString()"
          [data]="data"
        ></lab-icon>
      </ng-container>
    </ng-container>
    <lab-icon
      *ngIf="!recipe.out"
      [iconId]="iconId"
      text="1"
      [data]="data"
    ></lab-icon>
  </div>
  <div *ngIf="recipe?.cost">
    <div>Cost: {{ recipe.cost }}</div>
  </div>
  <div *ngIf="item?.beacon as beacon">
    <table>
      <tr>
        <td>Effectivity</td>
        <td>{{ round(beacon.effectivity) }}</td>
      </tr>
      <tr>
        <td>Modules</td>
        <td>{{ beacon.modules }}</td>
      </tr>
      <tr>
        <td>Range</td>
        <td>{{ beacon.range }}</td>
      </tr>
      <tr *ngIf="beacon.type">
        <td>Energy type</td>
        <td>{{ beacon.type }}</td>
      </tr>
      <tr *ngIf="beacon.category">
        <td>Fuel category</td>
        <td>{{ beacon.category }}</td>
      </tr>
      <tr *ngIf="beacon.usage">
        <td>Energy consumption</td>
        <td>{{ power(beacon.usage) }}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="item?.belt as belt" class="nowrap">
    {{ round(belt.speed * displayRate) }}
    {{
      displayRate === DisplayRate.PerSecond
        ? 'Items/s'
        : displayRate === DisplayRate.PerMinute
        ? 'Items/m'
        : 'Items/h'
    }}
  </div>
  <div *ngIf="item?.pipe as pipe" class="nowrap">
    {{ round(pipe.speed * displayRate) }}
    {{
      displayRate === DisplayRate.PerSecond
        ? 'Items/s'
        : displayRate === DisplayRate.PerMinute
        ? 'Items/m'
        : 'Items/h'
    }}
  </div>
  <div *ngIf="item?.cargoWagon as wagon" class="nowrap">
    {{ round(wagon.size) }}
    Inventory stacks
  </div>
  <div *ngIf="item?.fluidWagon as wagon" class="nowrap">
    {{ round(wagon.capacity) }}
    Fluid capacity
  </div>
  <div *ngIf="item?.factory as factory">
    <table>
      <tr>
        <td>Crafting speed</td>
        <td>{{ round(factory.speed) }}</td>
      </tr>
      <tr *ngIf="factory.modules">
        <td>Modules</td>
        <td>{{ factory.modules }}</td>
      </tr>
      <tr *ngIf="factory.type">
        <td>Energy type</td>
        <td>{{ factory.type }}</td>
      </tr>
      <tr *ngIf="factory.category">
        <td>Fuel category</td>
        <td>{{ factory.category }}</td>
      </tr>
      <tr *ngIf="factory.usage">
        <td>Energy consumption</td>
        <td>{{ power(factory.usage) }}</td>
      </tr>
      <tr *ngIf="factory.drain">
        <td>Drain</td>
        <td>
          {{ power(factory.drain) }}
        </td>
      </tr>
      <tr *ngIf="factory.pollution">
        <td>Pollution</td>
        <td>{{ round(factory.pollution) }}/m</td>
      </tr>
      <ng-container *ngIf="factory.silo as silo">
        <tr>
          <td>Rocket Parts Required</td>
          <td>{{ silo.parts }}</td>
        </tr>
        <tr>
          <td>Launch Time (ticks)</td>
          <td>{{ silo.launch }}</td>
        </tr>
      </ng-container>
    </table>
  </div>
  <div *ngIf="item?.fuel as fuel">
    <table>
      <tr>
        <td>Category</td>
        <td>{{ fuel.category }}</td>
      </tr>
      <tr>
        <td>Value</td>
        <td>{{ round(fuel.value) }} MJ</td>
      </tr>
    </table>
  </div>
  <div *ngIf="item?.module as module">
    <table>
      <tr *ngIf="module.consumption">
        <td>Consumption</td>
        <td>{{ toBonusPercent(module.consumption) }}</td>
      </tr>
      <tr *ngIf="module.speed">
        <td>Speed</td>
        <td>{{ toBonusPercent(module.speed) }}</td>
      </tr>
      <tr *ngIf="module.productivity">
        <td>Productivity</td>
        <td>{{ toBonusPercent(module.productivity) }}</td>
      </tr>
      <tr *ngIf="module.pollution">
        <td>Pollution</td>
        <td>{{ toBonusPercent(module.pollution) }}</td>
      </tr>
    </table>
  </div>
</div>
