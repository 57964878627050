<lab-settings
  [data]="data$ | async"
  [base]="base$ | async"
  [factories]="factories$ | async"
  [settings]="settings$ | async"
  [preferences]="preferences$ | async"
  [columns]="columns$ | async"
  (resetSettings)="resetSettings()"
  (closeSettings)="closeSettings.emit()"
  (saveState)="saveState($event)"
  (removeState)="removeState($event)"
  (setPreset)="setPreset($event)"
  (setBase)="setBase($event)"
  (setDisabledRecipes)="setDisabledRecipes($event)"
  (setExpensive)="setExpensive($event)"
  (addFactory)="addFactory($event)"
  (removeFactory)="removeFactory($event)"
  (raiseFactory)="raiseFactory($event)"
  (setFactory)="setFactory($event)"
  (setModuleRank)="setModuleRank($event)"
  (setBeaconCount)="setBeaconCount($event)"
  (setBeacon)="setBeacon($event)"
  (setBeaconModule)="setBeaconModule($event)"
  (setOverclock)="setOverclock($event)"
  (setBeaconReceivers)="setBeaconReceivers($event)"
  (setBelt)="setBelt($event)"
  (setPipe)="setPipe($event)"
  (setFuel)="setFuel($event)"
  (setFlowRate)="setFlowRate($event)"
  (setCargoWagon)="setCargoWagon($event)"
  (setFluidWagon)="setFluidWagon($event)"
  (setMiningBonus)="setMiningBonus($event)"
  (setResearchSpeed)="setResearchSpeed($event)"
  (setInserterTarget)="setInserterTarget($event)"
  (setInserterCapacity)="setInserterCapacity($event)"
  (setDisplayRate)="setDisplayRate($event)"
  (setColumns)="setColumns($event)"
  (setSimplex)="setSimplex($event)"
>
</lab-settings>
