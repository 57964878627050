<div class="top-bar">
  <div>
    <div class="links">
      <div class="header">
        <a [href]="homeHref"
          ><h1>{{ title }}</h1></a
        >
      </div>
      <ng-container *ngIf="game !== Game.Factorio">
        <span class="separator">|</span>
        <a href="factorio">Factorio</a>
      </ng-container>
      <ng-container *ngIf="game !== Game.DysonSphereProgram">
        <span class="separator">|</span>
        <a href="dsp">Dyson Sphere Program</a>
      </ng-container>
      <ng-container *ngIf="game !== Game.Satisfactory">
        <span class="separator">|</span>
        <a href="satisfactory">Satisfactory</a>
      </ng-container>
    </div>
    <div class="links">
      <a target="_blank" href="https://github.com/factoriolab/factorio-lab/wiki"
        >Wiki</a
      >
      <span class="separator">|</span>
      <a target="_blank" href="https://github.com/factoriolab/factorio-lab"
        >Source</a
      >
      <span class="separator">|</span>
      <a target="_blank" href="https://discord.gg/N4FKV687x2">Discord</a>
      <span class="separator">|</span>
      <a target="_blank" href="https://ko-fi.com/dcbroad3">Support</a>
    </div>
  </div>
</div>
<ng-container *ngIf="(datasets$ | async).length; else loading">
  <main *ngIf="!error.message; else errorTile">
    <lab-settings-container
      *ngIf="showSettings"
      @slideLeftRight
      (closeSettings)="showSettings = !showSettings"
    >
    </lab-settings-container>
    <div class="view">
      <div *ngIf="showPoll" class="poll">
        <h2>Poll</h2>
        <div class="panel">
          <a target="_blank" [href]="poll">Have a minute to answer a poll?</a>
          <div class="flex buttons">
            <button (click)="hidePoll(true)">
              <span class="text">Don't show again</span>
            </button>
            <button (click)="hidePoll()">
              <span class="text">Close</span>
            </button>
          </div>
        </div>
      </div>
      <lab-products-container></lab-products-container>
      <div *ngIf="(products$ | async).length">
        <ul role="navigation" class="tabs">
          <li
            routerLink="/list"
            routerLinkActive="active"
            queryParamsHandling="preserve"
          >
            <span>List</span>
          </li>
          <li
            routerLink="/flow"
            routerLinkActive="active"
            queryParamsHandling="preserve"
          >
            <span>Flow</span>
          </li>
          <li
            routerLink="/matrix"
            routerLinkActive="active"
            queryParamsHandling="preserve"
          >
            <span>Matrix</span>
          </li>
          <li *ngIf="!showSettings" (click)="showSettings = true">
            <span>Settings</span>
          </li>
        </ul>
        <div class="panel">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div>
        <div class="panel dark flex tween">
          <div *ngIf="result$ | async as result">
            <span
              *ngIf="
                result.result === MatrixResultType.Solved ||
                  result.result === MatrixResultType.Cached;
                else notSolved
              "
              >Simplex {{ result.result }}: {{ result.time }}ms,
              {{ result.pivots }} pivots<br />
              Size: {{ result.A.length }} x {{ result.A[0].length }}, Cost:
              {{ result.O[result.O.length - 1].toPrecision(2) }}
            </span>
            <ng-template #notSolved>
              <span>Simplex {{ result.result }}</span>
            </ng-template>
          </div>
          <span>{{ version }}</span>
        </div>
      </div>
    </div>
  </main>
</ng-container>
<ng-template #loading>
  <div class="view msg load">
    <div><div class="panel">Loading data, please wait...</div></div>
  </div>
</ng-template>
<ng-template #errorTile>
  <div class="view msg err">
    <div>
      <h2>Error</h2>
      <div class="panel invalid">
        <div>The application has encountered an error:</div>
        <br />
        <div>{{ error.message }}</div>
        <br />
        <a [href]="homeHref">Click here to reset</a>
      </div>
    </div>
  </div>
</ng-template>
