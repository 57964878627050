<lab-products
  [data]="data$ | async"
  [productSteps]="productSteps$ | async"
  [products]="products$ | async"
  [itemSettings]="itemSettings$ | async"
  [recipeSettings]="recipeSettings$ | async"
  [factories]="factories$ | async"
  [displayRate]="displayRate$ | async"
  (removeProduct)="removeProduct($event)"
  (setItem)="setItem($event)"
  (setRate)="setRate($event)"
  (setRateType)="setRateType($event)"
  (setVia)="setVia($event)"
  (setViaSetting)="setViaSetting($event)"
  (setViaFactoryModules)="setViaFactoryModules($event)"
  (setViaBeaconCount)="setViaBeaconCount($event)"
  (setViaBeacon)="setViaBeacon($event)"
  (setViaBeaconModules)="setViaBeaconModules($event)"
  (setViaOverclock)="setViaOverclock($event)"
  (addProduct)="addProduct($event)"
  (setDisplayRate)="setDisplayRate($event)"
>
</lab-products>
