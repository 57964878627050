<lab-list
  [mode]="mode"
  [selected]="selected"
  [data]="data$ | async"
  [itemSettings]="itemSettings$ | async"
  [itemRaw]="itemRaw$ | async"
  [recipeSettings]="recipeSettings$ | async"
  [recipeRaw]="recipeRaw$ | async"
  [settings]="settings$ | async"
  [factories]="factories$ | async"
  [beltSpeed]="beltSpeed$ | async"
  [steps]="steps ? steps : (steps$ | async)"
  [disabledRecipes]="disabledRecipes$ | async"
  [displayRate]="displayRate$ | async"
  [inserterTarget]="inserterTarget$ | async"
  [inserterCapacity]="inserterCapacity$ | async"
  [columns]="columns$ | async"
  [modifiedIgnore]="modifiedIgnore$ | async"
  [modifiedBelt]="modifiedBelt$ | async"
  [modifiedWagon]="modifiedWagon$ | async"
  [modifiedFactory]="modifiedFactory$ | async"
  [modifiedOverclock]="modifiedOverclock$ | async"
  [modifiedBeacons]="modifiedBeacons$ | async"
  (ignoreItem)="ignoreItem($event)"
  (setBelt)="setBelt($event)"
  (setWagon)="setWagon($event)"
  (setFactory)="setFactory($event)"
  (setFactoryModules)="setFactoryModules($event)"
  (setBeaconCount)="setBeaconCount($event)"
  (setBeacon)="setBeacon($event)"
  (setBeaconModules)="setBeaconModules($event)"
  (setBeaconTotal)="setBeaconTotal($event)"
  (setOverclock)="setOverclock($event)"
  (setColumns)="setColumns($event)"
  (resetItem)="resetItem($event)"
  (resetRecipe)="resetRecipe($event)"
  (resetIgnore)="resetIgnore()"
  (resetBelt)="resetBelt()"
  (resetWagon)="resetWagon()"
  (resetFactory)="resetFactory()"
  (resetOverclock)="resetOverclock()"
  (resetBeacons)="resetBeacons()"
  (setDisabledRecipes)="setDisabledRecipes($event)"
  (setDefaultRecipe)="setDefaultRecipe($event)"
>
</lab-list>
