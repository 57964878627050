<div class="flex">
  <h2 class="header">Settings</h2>
  <div class="flex">
    <button
      class="dialog-button"
      title="Delete all settings"
      (click)="clickResetSettings()"
    >
      <i class="material-icons">delete</i>
    </button>
    <button class="dialog-button" (click)="closeSettings.emit()">
      <i class="material-icons">close</i>
    </button>
  </div>
</div>
<div class="panel">
  <div class="hole">
    <div>
      <div class="header">
        <lab-info
          text="Saved states"
          tooltip="Click edit to name and save the current calculator state.
Select a saved state from the dropdown to load it into the calculator."
        ></lab-info>
      </div>
      <div class="flex states">
        <ng-container *ngIf="!editState; else stateInput">
          <lab-options
            title="Select saved state"
            [selected]="state"
            [options]="savedStates"
            (selectId)="setState($event)"
          ></lab-options>
          <button
            *ngIf="state"
            title="Delete saved state"
            (click)="clickRemoveState()"
          >
            <i class="material-icons">delete</i>
          </button>
          <button
            *ngIf="BrowserUtility.search"
            title="Name and save state"
            (click)="toggleEditState()"
          >
            <i class="material-icons">edit</i>
          </button>
        </ng-container>
        <ng-template #stateInput>
          <input
            type="text"
            placeholder="Enter a name..."
            [(ngModel)]="tempState"
          />
          <button
            *ngIf="tempState && BrowserUtility.search"
            title="Save state"
            (click)="clickSaveState()"
          >
            <i class="material-icons">save</i>
          </button>
          <button title="Cancel" (click)="toggleEditState()">
            <i class="material-icons">close</i>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="hole">
    <div>
      <div class="flex tween">
        <div class="header">Recipes</div>
        <a
          *ngIf="data.game === Game.Factorio"
          class="small"
          href="https://github.com/factoriolab/factorio-lab/issues/new?assignees=&labels=mod+support&template=mod-request.md&title="
          target="_blank"
          >Request a mod</a
        >
      </div>
      <table class="thin">
        <tr>
          <td>
            <lab-info
              text="Game"
              tooltip="Select which game calculator to use,
or use the links in the title bar."
            ></lab-info>
          </td>
          <td>
            <lab-options
              title="Select game"
              [selected]="data.game"
              [options]="GameOptions"
              (selectId)="setGame($event)"
            ></lab-options>
          </td>
        </tr>
        <tr *ngIf="data.game === Game.Factorio">
          <td>
            <lab-info
              text="Mod set"
              tooltip="Select which version or set of mods in Factorio to use."
            ></lab-info>
          </td>
          <td>
            <lab-options
              title="Select base recipe set"
              [selected]="settings.baseId"
              [options]="baseOptions"
              (selectId)="setBase.emit($event)"
            ></lab-options>
          </td>
        </tr>
        <tr>
          <td>
            <lab-info
              text="Disabled"
              tooltip="Click to modify which recipes are disabled.
Disabled recipes will not be used in the solution."
            ></lab-info>
          </td>
          <td>
            <lab-toggle
              [data]="data"
              [selected]="settings.disabledRecipes"
              (selectIds)="
                setDisabledRecipes.emit({
                  value: $event,
                  def: data.defaults.disabledRecipes
                })
              "
            >
            </lab-toggle>
          </td>
        </tr>
        <tr *ngIf="data.game === Game.Factorio">
          <td>
            <lab-info
              text="Difficulty"
              tooltip="Select whether to use Normal or
Expensive difficulty recipes."
            ></lab-info>
          </td>
          <td>
            <lab-options
              title="Select recipe difficulty"
              [selected]="settings.expensive"
              [options]="difficultyOptions"
              (selectId)="setExpensive.emit($event)"
            ></lab-options>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="hole">
    <div>
      <div class="header">Display</div>
      <table class="x-wide">
        <tr>
          <td>
            <lab-info
              text="Columns & precision"
              tooltip="Click to select the visible columns and decimal
or fractional precision for visible columns."
            ></lab-info>
          </td>
          <td>
            <lab-columns
              class="relative"
              [game]="data.game"
              [columns]="columns"
              (setColumns)="setColumns.emit($event)"
            >
              <button class="grow" title="Set columns visibility and precision">
                <span class="text">{{ columnsButton }}</span>
              </button>
            </lab-columns>
          </td>
        </tr>
        <tr>
          <td>
            <lab-info
              text="Display rates as"
              tooltip="Set the unit of time to use in rates."
            ></lab-info>
          </td>
          <td>
            <lab-options
              title="Select display rate"
              [selected]="settings.displayRate"
              [options]="DisplayRateOptions"
              (selectId)="
                setDisplayRate.emit({
                  value: $event,
                  prev: settings.displayRate
                })
              "
            ></lab-options>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="hole">
    <div>
      <div class="header">Factory</div>
      <table class="thin">
        <tr>
          <td>
            <lab-info
              text="Preset"
              tooltip="Select a default preset of factory settings to use."
            ></lab-info>
          </td>
          <td>
            <lab-options
              title="Select preset factory"
              [selected]="settings.preset"
              [options]="presetOptions"
              (selectId)="setPreset.emit($event)"
            ></lab-options>
          </td>
        </tr>
      </table>
      <table class="factories">
        <ng-container
          *ngFor="let f of factoryRows; let i = index; let last = last"
        >
          <tr *ngIf="factories.entities[f] as factory">
            <td>
              <ng-container *ngIf="!f">
                <lab-select
                  *ngIf="factoryOptions.length"
                  header="Select a factory"
                  [data]="data"
                  [options]="factoryOptions"
                  [data]="data"
                  (selectId)="
                    addFactory.emit({
                      value: $event,
                      def: data.defaults.factoryRank
                    })
                  "
                >
                  <button title="Add factory preference">
                    <i class="material-icons">add</i>
                  </button>
                </lab-select>
              </ng-container>
              <button
                *ngIf="f"
                title="Remove factory preference"
                (click)="
                  removeFactory.emit({
                    value: f,
                    def: data.defaults.factoryRank
                  })
                "
              >
                <i class="material-icons">remove</i>
              </button>
            </td>
            <td>
              <lab-icon
                *ngIf="f && !factoryOptions.length"
                class="pad"
                [data]="data"
                [iconId]="f"
                [tooltip]="data.itemEntities[f].name"
                [item]="data.itemEntities[f]"
              >
              </lab-icon>
              <lab-select
                *ngIf="f && factoryOptions.length"
                header="Select a factory"
                [data]="data"
                [selected]="f"
                [options]="factoryOptions"
                [data]="data"
                (selectId)="
                  setFactory.emit({
                    id: f,
                    value: $event,
                    def: data.defaults.factoryRank
                  })
                "
              >
                <lab-icon
                  class="button"
                  [data]="data"
                  [iconId]="f"
                  [tooltip]="data.itemEntities[f].name"
                  [item]="data.itemEntities[f]"
                >
                </lab-icon>
              </lab-select>
              <lab-icon
                *ngIf="!f"
                class="pad"
                tooltip="Factory defaults"
                iconId="production"
                [data]="data"
              ></lab-icon>
            </td>
            <td>
              <ng-container *ngIf="factory.moduleRank">
                <lab-ranker
                  *ngIf="data.game === Game.Factorio; else moduleSelect"
                  [data]="data"
                  [selected]="factory.moduleRank"
                  [options]="data.moduleIds"
                  (selectIds)="
                    setModuleRank.emit({
                      id: f,
                      value: $event,
                      def:
                        f === ''
                          ? data.defaults.moduleRank
                          : factories.entities[''].moduleRank
                    })
                  "
                >
                  <lab-icon
                    class="button"
                    tooltip="Module preferences"
                    [data]="data"
                    [iconId]="
                      factory.moduleRank.length
                        ? factory.moduleRank[0]
                        : ItemId.Module
                    "
                    [text]="
                      factory.moduleRank.length > 1
                        ? factory.moduleRank.length.toString()
                        : null
                    "
                  ></lab-icon>
                </lab-ranker>
                <ng-template #moduleSelect>
                  <lab-select
                    header="Select a module"
                    [data]="data"
                    [selected]="factory.moduleRank[0]"
                    [options]="data.moduleIds"
                    [displayRate]="settings.displayRate"
                    (selectId)="
                      setModuleRank.emit({
                        id: f,
                        value: [$event],
                        def:
                          f === ''
                            ? data.defaults.moduleRank
                            : factories.entities[''].moduleRank
                      })
                    "
                  >
                    <lab-icon
                      class="button"
                      tooltip="Module preferences"
                      [data]="data"
                      [iconId]="
                        factory.moduleRank.length
                          ? factory.moduleRank[0]
                          : ItemId.Module
                      "
                      [text]="
                        factory.moduleRank.length > 1
                          ? factory.moduleRank.length.toString()
                          : null
                      "
                    ></lab-icon>
                  </lab-select>
                </ng-template>
              </ng-container>
            </td>
            <td *ngIf="data.game === Game.Satisfactory">
              <input
                type="number"
                title="Default overclock"
                placeholder="100"
                min="0"
                max="250"
                step="10"
                labValidateOverclock
                [ngModel]="factory.overclock"
                (input)="changeOverclock(f, $event)"
              />
              %
            </td>
            <ng-container *ngIf="data.game === Game.Factorio">
              <td>
                <ng-container *ngIf="factory.beaconCount != null">
                  <lab-input
                    title="Default number of beacons"
                    placeholder="# Beacons"
                    [digits]="2"
                    [value]="factory.beaconCount"
                    (setValue)="changeBeaconCount(f, $event)"
                  >
                  </lab-input>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="gtZero(factory.beaconCount)">
                  <ng-container
                    *ngIf="data.beaconIds.length === 1; else beaconPicker"
                  >
                    <lab-icon
                      class="pad"
                      [data]="data"
                      [iconId]="factory.beacon"
                      [tooltip]="data.itemEntities[factory.beacon].name"
                      [item]="data.itemEntities[factory.beacon]"
                    ></lab-icon>
                  </ng-container>
                  <ng-template #beaconPicker>
                    <lab-select
                      header="Select a beacon"
                      [data]="data"
                      [selected]="factory.beacon"
                      [options]="data.beaconIds"
                      (selectId)="
                        setBeacon.emit({
                          id: f,
                          value: $event,
                          def:
                            f === ''
                              ? data.defaults.beacon
                              : factories.entities[''].beacon
                        })
                      "
                    >
                      <lab-icon
                        class="button"
                        tooltip="Select default beacon"
                        [data]="data"
                        [iconId]="factory.beacon"
                      ></lab-icon>
                    </lab-select>
                  </ng-template>
                </ng-container>
              </td>
              <td>
                <lab-select
                  *ngIf="gtZero(factory.beaconCount)"
                  header="Select a module"
                  [data]="data"
                  [selected]="factory.beaconModule"
                  [options]="data.beaconModuleIds"
                  [includeEmptyModule]="true"
                  (selectId)="
                    setBeaconModule.emit({
                      id: f,
                      value: $event,
                      def:
                        f === ''
                          ? data.defaults.beaconModule
                          : factories.entities[''].beaconModule
                    })
                  "
                >
                  <lab-icon
                    class="button"
                    tooltip="Beacon module"
                    [data]="data"
                    [iconId]="factory.beaconModule"
                  ></lab-icon>
                </lab-select>
              </td>
            </ng-container>
            <td>
              <button
                *ngIf="i > 1"
                title="Raise factory rank"
                (click)="
                  raiseFactory.emit({
                    value: f,
                    def: data.defaults.factoryRank
                  })
                "
              >
                <i class="material-icons">arrow_drop_up</i>
              </button>
            </td>
          </tr>
        </ng-container>
      </table>
      <table class="icons">
        <ng-container *ngIf="data.game === Game.Factorio">
          <tr>
            <td colspan="2">
              <div class="flex option" (click)="toggleBeaconPower()">
                <div
                  class="checkbox"
                  [class.checked]="settings.beaconReceivers"
                ></div>
                <lab-info
                  text="Estimate beacon power usage"
                  tooltip="Enable to estimate power usage of beacons as well as factories.
Requires an estimated average number of receivers per beacon."
                ></lab-info>
              </div>
            </td>
          </tr>
          <tr *ngIf="settings.beaconReceivers">
            <td>
              <lab-info
                text="Average beacon receivers"
                tooltip="The average number of factories affected by each beacon.
Minimum possible value is 1."
              ></lab-info>
            </td>
            <td>
              <div>
                <lab-input
                  title="Set average beacon receivers"
                  placeholder="# Receivers"
                  [digits]="2"
                  [value]="settings.beaconReceivers"
                  minimum="1"
                  (setValue)="setBeaconReceivers.emit($event)"
                >
                </lab-input>
              </div>
            </td>
          </tr>
        </ng-container>
        <tr>
          <td>
            <lab-info
              text="Default transport belt"
              tooltip="Select the default belt to be used by all items."
            ></lab-info>
          </td>
          <td>
            <div>
              <lab-select
                header="Select a belt"
                [data]="data"
                [selected]="settings.belt"
                [options]="data.beltIds"
                [displayRate]="settings.displayRate"
                (selectId)="
                  setBelt.emit({ value: $event, def: data.defaults.belt })
                "
              >
                <lab-icon
                  class="button"
                  tooltip="Select default belt"
                  [data]="data"
                  [iconId]="settings.belt"
                ></lab-icon>
              </lab-select>
            </div>
          </td>
        </tr>
        <tr *ngIf="data.pipeIds.length">
          <td>
            <lab-info
              text="Default pipe"
              tooltip="Select the default pipe to be used by all fluids."
            ></lab-info>
          </td>
          <td>
            <div>
              <lab-select
                header="Select a pipe"
                [data]="data"
                [selected]="settings.pipe"
                [options]="data.pipeIds"
                [displayRate]="settings.displayRate"
                (selectId)="
                  setPipe.emit({ value: $event, def: data.defaults.pipe })
                "
              >
                <lab-icon
                  class="button"
                  tooltip="Select default pipe"
                  [data]="data"
                  [iconId]="settings.pipe"
                ></lab-icon>
              </lab-select>
            </div>
          </td>
        </tr>
        <tr *ngIf="data.cargoWagonIds.length > 1">
          <td>
            <lab-info
              text="Default cargo wagon"
              tooltip="Select the default cargo wagon to be used by all items."
            ></lab-info>
          </td>
          <td>
            <div>
              <lab-select
                header="Select a cargo wagon"
                [data]="data"
                [selected]="settings.cargoWagon"
                [options]="data.cargoWagonIds"
                (selectId)="
                  setCargoWagon.emit({
                    value: $event,
                    def: data.defaults.cargoWagon
                  })
                "
              >
                <lab-icon
                  class="button"
                  tooltip="Select default cargo wagon"
                  [iconId]="settings.cargoWagon"
                  [data]="data"
                ></lab-icon>
              </lab-select>
            </div>
          </td>
        </tr>
        <tr *ngIf="data.fluidWagonIds.length > 1">
          <td>
            <lab-info
              text="Default fluid wagon"
              tooltip="Select the default fluid wagon to be used by all fluids."
            ></lab-info>
          </td>
          <td>
            <div>
              <lab-select
                header="Select a fluid wagon"
                [data]="data"
                [selected]="settings.fluidWagon"
                [options]="data.fluidWagonIds"
                (selectId)="
                  setFluidWagon.emit({
                    value: $event,
                    def: data.defaults.fluidWagon
                  })
                "
              >
                <lab-icon
                  class="button"
                  tooltip="Select default fluid wagon"
                  [iconId]="settings.fluidWagon"
                  [data]="data"
                ></lab-icon>
              </lab-select>
            </div>
          </td>
        </tr>
        <tr *ngIf="sortedFuels.length && data.game !== Game.Satisfactory">
          <td>
            <lab-info
              text="Chemical fuel"
              tooltip="Select the default chemical to be used where applicable.
This does not affect burners that use non-chemical fuels."
            ></lab-info>
          </td>
          <td>
            <div>
              <lab-select
                header="Select a fuel"
                [data]="data"
                [selected]="settings.fuel"
                [options]="sortedFuels"
                (selectId)="
                  setFuel.emit({ value: $event, def: data.defaults.fuel })
                "
              >
                <lab-icon
                  class="button"
                  tooltip="Select fuel"
                  [iconId]="settings.fuel"
                  [data]="data"
                ></lab-icon>
              </lab-select>
            </div>
          </td>
        </tr>
      </table>
      <table class="thin" *ngIf="data.game === Game.Factorio">
        <tr>
          <td>Pipe flow rate</td>
          <td>
            <div class="flex">
              <input
                type="number"
                title="Enter pipe flow rate"
                placeholder="1500"
                min="0"
                step="1"
                data-test="lab-settings-flow-rate"
                [formControl]="ctrlFlowRate"
                [value]="settings.flowRate"
                (input)="emitNumber(setFlowRate, $event, 0)"
              />
              <div>
                u/sec
                <a
                  href="https://wiki.factorio.com/Fluid_system#Pipelines"
                  target="_blank"
                  >Wiki</a
                >
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            Inserter target
            <a
              href="https://wiki.factorio.com/Inserters#Inserter_Throughput"
              target="_blank"
              >Wiki</a
            >
          </td>
          <td>
            <lab-options
              title="Select inserter target"
              [selected]="settings.inserterTarget"
              [options]="InserterTargetOptions"
              (selectId)="setInserterTarget.emit($event)"
            ></lab-options>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="hole" *ngIf="data.game !== Game.Satisfactory">
    <div>
      <div class="header">Bonuses</div>
      <table class="wide">
        <tr *ngIf="data.game === Game.Factorio">
          <td>
            <lab-info
              text="Mining productivity"
              tooltip="Enter the current mining productivity bonus percent.
Default is 0%."
            ></lab-info>
          </td>
          <td class="right">
            +
            <input
              type="number"
              title="Enter mining productivity bonus"
              placeholder="Mining productivity"
              min="0"
              step="10"
              data-test="lab-settings-mining-bonus"
              [formControl]="ctrlMiningProductivity"
              [value]="settings.miningBonus"
              (input)="emitNumber(setMiningBonus, $event, 0)"
            />
            %
          </td>
        </tr>
        <tr *ngIf="data.game === Game.DysonSphereProgram">
          <td>
            <lab-info
              text="Mining speed"
              tooltip="Enter the current mining speed with bonus.
Default is 100%."
            ></lab-info>
          </td>
          <td class="right">
            <input
              type="number"
              title="Enter mining speed"
              placeholder="Mining speed"
              min="100"
              step="10"
              data-test="lab-settings-mining-bonus"
              [formControl]="ctrlMiningSpeed"
              [value]="settings.miningBonus + 100"
              (input)="emitNumber(setMiningBonus, $event, 0, -100)"
            />
            %
          </td>
        </tr>
        <tr *ngIf="data.game === Game.Factorio">
          <td>
            <lab-info
              text="Research speed"
              tooltip="Enter the current level of research speed bonus.
This setting only affects research products."
            ></lab-info>
          </td>
          <td>
            <lab-options
              title="Select research speed bonus"
              [selected]="settings.researchSpeed"
              [options]="ResearchSpeedOptions"
              (selectId)="setResearchSpeed.emit($event)"
            ></lab-options>
          </td>
        </tr>
        <tr *ngIf="data.game === Game.Factorio">
          <td>
            <lab-info
              text="Inserter capacity"
              tooltip="Enter the current level of inserter capacity bonus.
This setting only affects inserter estimates."
            ></lab-info>
          </td>
          <td>
            <lab-options
              title="Select inserter capacity bonus"
              [selected]="settings.inserterCapacity"
              [options]="InserterCapacityOptions"
              (selectId)="setInserterCapacity.emit($event)"
            ></lab-options>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="hole">
    <div>
      <div class="header">Advanced</div>
      <table class="wide">
        <tr>
          <td>
            <lab-info
              text="Simplex solver"
              tooltip="Select to enable/disable the simplex solver.
Disable simplex to more easily modify settings
when simplex is slow and causes lag."
            ></lab-info>
          </td>
          <td>
            <lab-options
              title="Disable to get a faster but incomplete result"
              [selected]="preferences.simplex"
              [options]="enabledOptions"
              (selectId)="setSimplex.emit($event)"
            ></lab-options>
          </td>
        </tr>
        <tr *ngIf="!preferences.simplex">
          <td colspan="2">
            <span class="invalid"
              >Disabling simplex improves performance but usually results in an
              incomplete solution.</span
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
